import { camelCase, startCase } from 'lodash';
import moment from 'moment/moment';
import React from 'react';
import * as Route from 'services/route';
import { isClient } from 'services/auth';
import state from 'data/state';
import iconType from 'utils/iconType';
import { formatNumber } from 'utils/number';
import * as AuditRatio from 'utils/auditRatio';
import * as Contact from 'views/contact/config';
import * as Client from 'views/client/config';
import { exportAgreement } from 'views/venueAgreement/config';

export const status = {
  PROPOSED: '',
  IN_PROGRESS: '',
  COMPLETED: '',
  DECLINED: '',
  DEAD: '',
};
const statusItems = Object.keys(status).map((key) => {
  status[key] = startCase(camelCase(key));
  return status[key];
});

export const stage = {
  RFP_REQUEST: 'RFP Request',
  RFP_SUBMITTED: 'RFP Submitted',
  FOLLOW_UP: '',
  ON_HOLD: '',
};
const stageItems = Object.keys(stage).map((key) => {
  stage[key] = stage[key] || startCase(camelCase(key));
  return stage[key];
});

export const stageInProgress = {
  ACTIVE: '',
  AUDIT: '',
  PAYMENT: '',
};
const stageInProgressItems = Object.keys(stageInProgress).map((key) => {
  stageInProgress[key] = stageInProgress[key] || startCase(camelCase(key));
  return stageInProgress[key];
});

const calcWeeks = (data) =>
  Math.round(moment(data.dateEnd).diff(moment(data.dateStart), 'days') / 7);

export const calcCpm = (data) =>
  data.mediaCost && data.impressionsTotal ? `$${parseFloat((data.mediaCost / data.impressionsTotal) * 1000).toFixed(2)}` : '-';

export const model = 'campaign';
export const label = 'Campaign';
export const apiPath = '/campaigns';
export const routePath = () => Route.paths.campaigns.campaigns;
export const schema = {
  id: {
    type: 'number',
    label: 'ID',
    required: true,
    primaryKey: true,
    form: {
      hide: true,
    },
  },
  title: {
    label: 'Title',
    required: true,
    grid: {
      minWidth: 150,
    },
  },
  fk_clientId: {
    label: 'Company',
    required: true,
    form: {
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'name',
      itemsUrl: () => Client.apiPath,
      detail: () => ({
        key: 'client.id',
        ...Client,
      }),
    },
  },
  'client.name': {
    label: 'Company',
    grid: {
      ...!isClient() && {
        detail: () => ({
          key: 'client.id',
          ...Client,
        }),
      },
    },
    form: {
      hide: true,
    },
  },
  type: {
    label: 'Type',
    required: true,
    grid: {
      hide: true,
    },
    form: {
      type: 'autocomplete',
      items: [
        'WNDWposter™ Display',
        'WNDWscape™ Display',
        'WNDWposter™ & WNDWscape™ Display',
        'WNDWposter™ & Postcard Display',
        'Postcard Display',
        'Brochure Display',
        'Program Displays',
        'Programmatic Media',
        'Booklet Display',
        'Magazine Display',
        'Coaster Display',
        'Sticker Display',
        'ALL SERVICES',
        'Keep-Up',
        'Meeting',
        'Design',
        'New Contact',
        'PUSH',
        'Print Production',
        'Coffee Cups',
        'Door Hangers',
      ],
    },
  },
  markets: {
    label: 'Markets',
    type: 'array',
    exportArrayKey: 'code',
    grid: {
      minWidth: 100,
      valueGetter: ({ data }) => data?.markets.map(({ code }) => code).join(', '),
    },
    form: {
      hide: true,
    },
  },
  status: {
    label: 'Status',
    required: true,
    grid: {
      minWidth: 120,
    },
    form: {
      type: 'autocomplete',
      items: Object.values(status),
      col: 1,
      onChange: async ({ item, fields, setFieldValue }) => {
        if (item) {
          const stageField = fields.find(({ key }) => key === 'stage');
          stageField.form.init({ values: { status: item.value }, field: stageField });
          //setFieldValue('stage', '');
        }
      },
      onSubmit: ({ updatedItem, setFieldValue }) => {
        setFieldValue('stage', updatedItem.stage);
      },
    },
  },
  stage: {
    label: 'Stage',
    grid: {},
    form: {
      type: 'autocomplete',
      init: ({ values, field }) => {
        if (values?.status === status.PROPOSED) {
          field.form.initItems(Object.values(stage));
          field.form.readOnly = false;
        } else if (values?.status === status.IN_PROGRESS) {
          field.form.initItems(Object.values(stageInProgress));
          field.form.readOnly = false;
          // field.form.readOnly = true;
        } else {
          field.form.initItems([]);
          field.form.readOnly = true;
        }
        /* else if (values?.status === status.COMPLETED) {
         field.form.initItems([stageInProgress.PAYMENT]);
         field.form.readOnly = true;
        } */
      },
      onChange: async ({ item, values, fields, setFieldValue }) => {
        if (item) {
          [ 'due', 'dateHoldExpires' ].forEach((key) => {
            const field = fields.find((_field) => _field.key === key);
            field.form.init({ values: { ...values, stage: item.value }, field });
          });
        }
      },
      col: 2,
    },
  },
  // method: {
  //   label: 'Method',
  //   form: {
  //     type: 'autocomplete',
  //     items: [
  //       'Standard',
  //       'GPS',
  //     ],
  //     colBreak: true,
  //   },
  // },
  due: {
    label: 'DUE',
    bold: true,
    type: 'text',
    query: true,
    form: {
      init: ({ values, field }) => {
        field.form.hide = values?.stage !== stage.RFP_REQUEST;
      },
      col: 1,
    },
  },
  dateHoldExpires: {
    label: 'Hold Expires',
    type: 'date',
    query: true,
    form: {
      init: ({ values, field }) => {
        field.form.hide = values?.stage !== stage.ON_HOLD;
      },
      col: 1,
    },
  },
  dateStart: {
    label: 'Contract Start',
    type: 'date',
    required: true,
    grid: {},
    form: {
      col: 1,
      colBreak: true,
    },
  },
  dateEnd: {
    label: 'Contract End',
    type: 'date',
    grid: {},
    form: {
      col: 2,
    },
  },
  dateFullInstall: {
    label: 'Full Install Date',
    type: 'date',
    form: {
      col: 1,
    },
  },
  dateFinalEnd: {
    label: 'Final End Date',
    type: 'date',
    form: {
      col: 2,
      readOnly: true,
      valueGetter: (data) => data.dateStart && data.dateEnd && data.dateFullInstall && moment(data.dateFullInstall).add(calcWeeks(data), 'week'),
    },
  },
  // daysOnDisplay: {
  //   label: 'Days',
  //   type: 'number',
  //   form: {
  //     col: 1,
  //     valueGetter: (data) => data.dateStart && data.dateEnd && moment(data.dateEnd).diff(moment(data.dateStart), 'days'),
  //   },
  // },
  weeksOnDisplay: {
    label: 'Weeks',
    type: 'number',
    form: {
      col: 1,
      valueGetter: (data) => data.dateStart && data.dateEnd && calcWeeks(data),
      infoColBreak: true,
    },
  },
  periodsOnDisplay: {
    label: 'Periods',
    type: 'number',
    form: {
      col: 2,
      valueGetter: (data) => data.dateStart && data.dateEnd && calcWeeks(data) / 4,
    },
  },
  // drops: {
  //   label: 'Drops',
  //   type: 'number',
  //   form: {
  //     col: 1,
  //   },
  // },
  // flatRate: {
  //   label: 'Flat Rate',
  //   type: 'boolean',
  //   form: {
  //     col: 2,
  //   },
  // },
  // installDays: {
  //   label: 'Est Install Days',
  //   form: {
  //     col: 1,
  //   },
  // },
  installDaysAct: {
    label: 'Act Install Days',
    form: {
      col: 1,
      // readOnly: true,
      // valueGetter: (data) => {
      //   /* Let (
      //       [ start = Min(DistributionRoute_Campaign::DateStart); end = Case(not IsEmpty(CampaignItemJoin_Campaign_Windowposter::DateInitialPosting); CampaignItemJoin_Campaign_Windowposter::DateInitialPosting; not IsEmpty(CampaignItemJoin_Campaign_Windowposter::ID) and Get(CurrentDate) > start; Get(CurrentDate); "") ; yearstart = Year (start); memday = Date ( 5 ; 25 ; yearstart ) + Choose ( DayOfWeek ( Date ( 5 ; 31 ; yearstart ) ) - 1; 0 ; 6 ; 5 ; 4 ; 3 ; 2 ; 1)];
      //
      //     If (not IsEmpty(Start) and not IsEmpty(end);
      //
      //     Weekdays (start; end) + 1 -
      //     If (Year(start) < Year(End); 1) -
      //       If (start ≤ Date ( 12 ; 25 ; yearstart ) and end ≥ Date ( 12 ; 25 ; yearstart ); 1) -
      //       If (start ≤ Date ( 7 ; 4 ; yearstart ) and end ≥ Date ( 7 ; 4 ; yearstart ); 1) -
      //       If (start ≤ Holiday ( yearstart ; 1 ; 2 ; 3 ) and end ≥ Holiday ( yearstart ; 1 ; 2 ; 3 ); 1) -
      //       If (start ≤ Holiday ( yearstart ; 2 ; 2 ; 3 ) and end ≥ Holiday ( yearstart ; 2 ; 2 ; 3 ); 1) -
      //       If (start ≤ memday and end ≥ memday; 1) -
      //       If (start ≤ Holiday ( yearstart ; 9 ; 2 ; 1 ) and end ≥ Holiday ( yearstart ; 9 ; 2 ; 1 ); 1) -
      //       If (start ≤ Holiday ( yearstart ; 11 ; 5 ; 4 ) and end ≥ Holiday ( yearstart ; 11 ;5 ; 4 ); 1)
      //   )) */
      // },
    },
  },
  hot: {
    label: 'Hot',
    type: 'number',
    grid: {
      cellClass: ({ data }) => data?.hot ? 'hot' : null,
    },
    form: {
      col: 2,
    },
  },
  /* digitalIntegration: {
    label: 'DI',
    type: 'boolean',
    form: {
      col: 1,
      colWidth: 'auto',
    },
  }, */
  /* dateStartDigital: {
    label: 'Digital Date',
    type: 'date',
    form: {
      // col: 1,
      /!* colWidth: 6, *!/
    },
  }, */
  // dateContract: {
  //   label: 'Contract Date',
  //   type: 'date',
  //   form: {
  //     col: 2,
  //     /* colWidth: 6, */
  //   },
  // },
  minBudget: {
    label: 'Min $',
    type: 'number',
    form: {
      col: 1,
    },
  },
  maxBudget: {
    label: 'Max $',
    type: 'number',
    form: {
      col: 2,
    },
  },
  avgBudget: {
    label: 'Avg $',
    type: 'number',
    grid: {},
    form: {
      hide: true,
    },
  },
  dateLastActivity: {
    type: 'date',
    label: 'Latest Activity',
    grid: {
      minWidth: 140,
    },
    form: {
      hide: true,
    },
  },
  // lastContact: {
  //   label: 'Last Contact',
  //   grid: {
  //     minWidth: 125,
  //     valueGetter: ({ data }) => data?.lastContact?.nameFull,
  //     detail: () => ({
  //       key: 'lastContact.id',
  //       ...Contact,
  //     }),
  //   },
  //   form: {
  //     hide: true,
  //   },
  // },
  routeSheetTotal: {
    label: 'Total Route Sheets',
    type: 'number',
    form: {
      info: true,
    },
  },
  routeSheetActive: {
    label: 'Active Route Sheets',
    type: 'number',
    form: {
      info: true,
    },
  },
  distributionGoal: {
    label: 'Dist Goal',
    type: 'number',
    grid: {},
    form: {
      info: true,
    },
  },
  venuesAlltime: {
    label: 'Venues All-Time',
    type: 'number',
    grid: {},
    form: {
      info: true,
    },
  },
  upCurrent: {
    label: 'Up Current',
    type: 'number',
    grid: {},
    form: {
      info: true,
    },
  },
  dateInitialRoute: {
    label: 'Initial Route Date',
    type: 'date',
    form: {
      info: true,
    },
  },
  venuesAudit: {
    label: 'Audit %',
    type: 'number',
    grid: {
      cellRenderer: ({ params, dataParent }) => AuditRatio.renderer(params.data),
    },
    form: {
      info: true,
      infoRenderer: AuditRatio.renderer,
    },
  },
  notes: {
    label: 'Notes',
    form: {
      type: 'textarea',
      colBreak: true,
      colWidth: 2,
      height: '60%',
    },
  },
  tags: {
    label: 'Tags',
    form: {
      type: 'tag',
      height: '40%',
    },
  },
  logo: {
    label: 'Logo',
    type: 'image',
    form: {
      colBreak: true,
      hideAdd: true,
      heightAuto: true,
      logo: true,
      colWidth: 1,
      download: {
        filename: ({ data }) => `${data.name}.jpg`,
      },
    },
  },
  fk_primaryContactId: {
    label: 'Primary Contact',
    form: {
      hide: true,
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'nameFull',
      itemsUrl: ({ fk_clientId: clientId, fk_primaryContactId: primaryContactId }) => `${Contact.apiPath}?clientId=${clientId}${primaryContactId ? `&includeId=${primaryContactId}` : ''}`,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
    },
  },
  address: {
    label: 'Address',
    form: {
      hide: true,
    },
    /* form: {
      colBreak: true,
    }, */
  },
  city: {
    label: 'City',
    // grid: {
    //   minWidth: 125,
    // },
    form: {
      hide: true,
    },
  },
  state: {
    label: 'State',
    form: {
      hide: true,
      col: 1,
      type: 'autocomplete',
      items: state,
    },
  },
  zip: {
    label: 'Zip',
    form: {
      hide: true,
      col: 2,
    },
  },
  email: {
    type: 'email',
    label: 'Email',
    form: {
      hide: true,
    },
  },
  phone: {
    label: 'Phone',
    form: {
      hide: true,
    },
  },
  mobile: {
    label: 'Mobile',
    form: {
      hide: true,
    },
  },
  fax: {
    label: 'Fax',
    form: {
      hide: true,
    },
  },
  fk_billingContactId: {
    label: 'Billing Contact',
    form: {
      hide: true,
      type: 'autocomplete',
      valueKey: 'id',
      labelKey: 'nameFull',
      itemsUrl: ({ fk_clientId: clientId, fk_billingContactId: billingContactId }) => `${Contact.apiPath}?clientId=${clientId}${billingContactId ? `&includeId=${billingContactId}` : ''}`,
      detail: () => ({
        key: 'contact.id',
        ...Contact,
      }),
    },
  },
  billingAddress: {
    label: 'Address',
    form: {
      hide: true,
    },
  },
  billingCity: {
    label: 'City',
    form: {
      hide: true,
    },
  },
  billingState: {
    label: 'State',
    form: {
      hide: true,
      col: 1,
      type: 'autocomplete',
      items: state,
    },
  },
  billingZip: {
    label: 'Zip',
    form: {
      hide: true,
      col: 2,
    },
  },
  billingEmail: {
    type: 'email',
    label: 'Email',
    form: {
      hide: true,
    },
  },
  billingPhone: {
    label: 'Phone',
    form: {
      hide: true,
    },
  },
  billingMobile: {
    label: 'Mobile',
    form: {
      hide: true,
    },
  },
  billingFax: {
    label: 'Fax',
    form: {
      hide: true,
    },
  },
  mediaCost: {
    label: 'Media',
    type: 'price',
    form: {
      col: 1,
      hide: true,
    },
  },
  printingCost: {
    label: 'Printing',
    type: 'price',
    form: {
      col: 2,
      hide: true,
    },
  },
  invoiceTotal: {
    label: 'Total',
    type: 'price',
    query: true,
    form: {
      col: 1,
      hide: true,
      onSubmit: ({ updatedItem, setFieldValue }) => {
        setFieldValue('invoiceBalance', updatedItem.invoiceBalance);
      },
    },
  },
  commissionRate: {
    label: 'Comm Rate',
    type: 'percent',
    form: {
      col: 1,
      colBreak: true,
      hide: true,
    },
  },
  commissionTotal: {
    label: 'Comm Total',
    type: 'price',
    form: {
      col: 2,
      hide: true,
      readOnly: true,
    },
  },
  impressionsContracted: {
    label: 'Imp Contracted',
    type: 'number',
    form: {
      col: 1,
      hide: true,
    },
  },
  impressionsTotal: {
    label: 'Imp Total',
    type: 'number',
    form: {
      col: 2,
      hide: true,
    },
  },
  cpm: {
    label: 'CPM',
    type: 'number',
    form: {
      hide: true,
      infoRenderer: calcCpm,
    },
  },
  agreementText: {
    label: 'Agreement',
    form: {
      type: 'textarea',
      height: 400,
      // col: 1,
      // colBreak: true,
      rowBreak: true,
      hide: true,
    },
  },
  date: {
    label: 'Date',
    type: 'date',
    form: {
      // col: 1,
      hide: true,
    },
  },
  invoiceNumber: {
    label: 'Inv #',
    form: {
      // col: 2,
      hide: true,
    },
  },
  invoicePaid: {
    label: 'Paid',
    type: 'price',
    query: true,
    form: {
      colBreak: true,
      hide: true,
      onSubmit: ({ updatedItem, setFieldValue }) =>
        setFieldValue('invoiceBalance', updatedItem.invoiceBalance),
    },
  },
  invoiceBalance: {
    label: 'Balance',
    type: 'price',
    form: {
      // col: 2,
      hide: true,
      readOnly: true,
    },
  },
  commissionPaid: {
    label: 'Comm Paid',
    type: 'boolean',
    form: {
      // col: 1,
      colBreak: true,
      hide: true,
    },
  },
  invoiceText: {
    label: 'Invoice',
    form: {
      type: 'textarea',
      height: 400,
      // col: 1,
      // colBreak: true,
      rowBreak: true,
      hide: true,
    },
  },
  owner: {
    label: 'Owner',
    /* grid: {}, */
    form: {
      hide: true,
    },
  },
  createdBy: {
    label: 'Created By',
    form: {
      info: true,
    },
  },
  dateCreation: {
    label: 'Created On',
    type: 'date',
    grid: {},
    form: {
      info: true,
    },
  },
  modifiedBy: {
    label: 'Modified By',
    form: {
      info: true,
    },
  },
  dateMod: {
    label: 'Modified On',
    type: 'date',
    time: true,
    form: {
      info: true,
    },
  },
};
