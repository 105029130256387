import React, { useState } from 'react';
import classNames from 'classnames';
import { Tabs, Tab } from 'react-bootstrap';
import 'styles/tabs.scss';


export default (props) => {
  const { tabs, onChange } = props;
  const [ tabActive, setTabActive ] = useState(props.tabActive || tabs[0].key);
  return (
    <Tabs
      activeKey={tabActive}
      onSelect={(k) => { setTabActive(k); onChange?.(k); }}
      variant="pills"
      className={classNames('tabs', `${tabActive}-active`)}
      mountOnEnter
      unmountOnExit
      transition={false}
    >
      {tabs.map(({ key, title, content }, i) => (
        <Tab
          eventKey={key}
          title={title}
          key={i}
          className={key}
        >
          {content}
        </Tab>
      ))}
    </Tabs>
  );
};
