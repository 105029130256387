import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Col, Row, Dropdown, Alert } from 'react-bootstrap';
import 'swiper/css';
import 'swiper/css/navigation';
import axios from 'axios';
import moment from 'moment';
import {
  capitalize,
  cloneDeep,
  pick,
  last,
  orderBy,
  chunk,
  range,
  debounce,
  truncate,
} from 'lodash';
import { PDFDocument, AcroFieldFlags, ImageAlignment, PDFString, PDFName, PDFArray } from 'pdf-lib';
import { saveAs } from 'file-saver';
import classNames from 'classnames';
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import PQueue from 'p-queue';
import * as XLSX from 'xlsx';
import * as ExcelJS from 'exceljs';
import fontkit from '@pdf-lib/fontkit';
import montserratBoldFont from 'fonts/Montserrat-Bold.ttf';
import montserratSemiBoldFont from 'fonts/Montserrat-SemiBold.ttf';
import montserratRegularFont from 'fonts/Montserrat-Regular.ttf';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { AutoSizer, List, WindowScroller } from 'react-virtualized';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faImage,
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
  faSquare,
} from '@fortawesome/free-solid-svg-icons';
import { Chart } from 'react-google-charts';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move';
import imageToBase64 from 'image-to-base64/browser';
import Crud from 'components/crud';
import Form from 'components/form';
import Tabs from 'components/tabs';
import Header from 'components/header';
import Modal from 'components/modal';
import Spinner from 'components/spinner';
import { alterSchema, getPrimaryKey } from 'utils/schema';
import iconType from 'utils/iconType';
import { formatNumber } from 'utils/number';
import { loadImage } from 'utils/image';
import { fitBounds } from 'utils/map';
import { isClient as _isClient } from 'services/auth';
import * as Contact from 'views/contact/config';
import * as Client from 'views/client/config';
import * as CampaignItem from 'views/campaignItem/config';
import * as CampaignMarket from 'views/campaignMarket/config';
import * as CampaignPlanGoogleDoc from 'views/campaignPlanGoogleDoc/config';
import * as CampaignStage from 'views/campaignStage/config';
import * as CampaignStageFlow from 'views/campaignStageFlow/config';
import Venue from 'views/venue/index';
import * as Job from 'views/job/config';
import * as RouteSheet from 'views/routeSheet/config';
import * as Activity from 'views/activity/config';
import * as GoogleDoc from 'views/googleDoc/config';
import Photo from 'views/photo/index';
import { apiPath as photoApiPath } from 'views/photo/config';
import Attachment from 'views/attachment/index';
import { apiPath, apiPath as venueApiPath, schema as venueSchema } from 'views/venue/config';
import * as CampaignReportPhoto from 'views/campaignReportPhoto/config';
import { model, schema, calcCpm } from './config';
import 'styles/detail.scss';
import 'styles/map.scss';
import 'styles/google.scss';
import 'styles/views/campaign.scss';
import 'styles/views/venue.scss';
import agreementPdf from './agreement.pdf';
import invoicePdf from './invoice.pdf';
import reportPdf from './report.pdf';
import reportMapMarkerImage from './report-map-marker.png';
import reportLogo from './report-logo.jpg';


const PlanGoogleDocs = ({ data, docType }) => {
  const [ googleDocs, setGoogleDocs ] = useState(null);
  const [ planGoogleDoc, setPlanGoogleDoc ] = useState(null);
  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});

  const googleDocsApiPath = `${GoogleDoc.apiPath(docType)}&campaignId=${data.id}`;
  const planGoogleDocKey = `googleDoc${docType.label}Id`;
  useEffect(async () => {
    if (docType === GoogleDoc.type.ATTACHMENT) {
      return;
    }
    const { data: planGoogleDoc } = (await axios.get(`${CampaignPlanGoogleDoc.apiPath}?campaignId=${data.id}`)) || {};
    if (planGoogleDoc) {
      const planGoogleDocId = planGoogleDoc[planGoogleDocKey];
      // const { googleDocRateId, googleDocDeckId } = planGoogleDoc;
      if (planGoogleDocId) {
        const { data: { items: docs } } = await axios.get(googleDocsApiPath);
        setGoogleDocs(docs);
        const doc = docs.find((doc) => doc.id === planGoogleDocId);
        if (doc) {
          setPlanGoogleDoc(doc);
        }
      }
    }
  }, []);

  const onSelectDoc = (doc) => {
    axios.post(CampaignPlanGoogleDoc.apiPath, { campaignId: data.id, [planGoogleDocKey]: doc.id });
    setPlanGoogleDoc(doc);
  };

  return (
    <div className="google-doc">
      {!planGoogleDoc ? (
          <Crud
            apiPath={googleDocsApiPath}
            schema={{ ...GoogleDoc.schema, ...{ name: { ...GoogleDoc.schema.name, label: 'Doc' } } }}
            schemaParent={schema}
            model={GoogleDoc.model}
            modelParent={model}
            label={GoogleDoc.label}
            nested
            dataParent={data}
            clientSide
            readOnly
            onRowClick={(node) => onSelectDoc(node.data)}
            onInit={({ rowData: { items } }) => {
              setGoogleDocs(items);
              forceUpdate();
            }}
          />
        ) :
        <>
          <Header
            label={false}
            icons={[
              {
                type: iconType.EXPORT,
                onClick: () => window.open(planGoogleDoc.webContentLink || planGoogleDoc.exportLinks?.[docType.exportLink]),
                tooltip: 'Export Doc',
              },
              {
                type: iconType.LINK_EXTERNAL,
                onClick: () => window.open(planGoogleDoc.webViewLink),
                tooltip: 'View Doc',
              },
            ]}
          />
          <Dropdown className="file">
            <Dropdown.Toggle variant="outline-secondary">Change</Dropdown.Toggle>
            <Dropdown.Menu>
              {googleDocs.map((doc, i) => (
                <React.Fragment key={i}>
                  <Dropdown.Item
                    className={classNames({ active: doc.id === planGoogleDoc.id })}
                    onClick={() => {
                      onSelectDoc(doc);
                    }}
                  >
                    {doc.name}
                  </Dropdown.Item>
                </React.Fragment>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <iframe
            src={`${planGoogleDoc.webViewLink}&rm=minimal`}
            allowFullScreen
          />
        </>}
    </div>
  );
};

const Agreement = ({ data, schema, apiPath, campaignFormState }) => {
  const [ agreementFormState, setAgreementFormState ] = useState(null);
  const [ invoiceFormState, setInvoiceFormState ] = useState(null);
  const [ agreementSchema, setAgreementSchema ] = useState(null);
  const [ invoiceSchema, setInvoiceSchema ] = useState(null);

  useEffect(() => {
    setAgreementSchema(alterSchema(schema, { formFields: [ 'mediaCost', 'printingCost', 'invoiceTotal', 'commissionRate', 'commissionTotal', 'impressionsContracted', 'impressionsTotal', 'agreementText' ] }));
    setInvoiceSchema(alterSchema(schema, { formFields: [ 'date', 'invoiceNumber', 'invoicePaid', 'invoiceBalance', 'commissionPaid', 'invoiceText' ] }));
  }, []);
  if (!agreementSchema || !invoiceSchema) {
    return null;
  }

  agreementSchema.invoiceTotal.form.onSubmit = ({ updatedItem }) =>
    invoiceFormState.setFieldValue('invoiceBalance', updatedItem.invoiceBalance);

  const exportDoc = async (type) => {
    const campaignFormData = campaignFormState.values;
    const agreementFormData = agreementFormState.values;
    const invoiceFormData = invoiceFormState.values;

    let pdf;
    let fields;
    let formData;
    let fileLabel;
    if (type === 'agreement') {
      pdf = agreementPdf;
      fields = [ 'date', 'contact', 'agreementText' ];
      formData = agreementFormData;
      fileLabel = 'agmt';
    } else {
      pdf = invoicePdf;
      fields = [ 'date', 'invoiceNumber', 'contact', 'invoiceText', 'invoiceTotal', 'invoicePaid', 'invoiceBalance' ];
      formData = invoiceFormData;
      fileLabel = 'inv';
    }

    const [
      { data: billingContact },
      { data: primaryContact },
      { data: client },
    ] = await Promise.all([
      data.fk_billingContactId && axios.get(`${Contact.apiPath}/${data.fk_billingContactId}`),
      data.fk_primaryContactId && axios.get(`${Contact.apiPath}/${data.fk_primaryContactId}`),
      axios.get(`${Client.apiPath}/${data.fk_clientId}`),
    ]);
    const pdfDoc = await PDFDocument.load(await fetch(pdf).then((res) => res.arrayBuffer()));
    const pdfForm = pdfDoc.getForm();

    fields.forEach((key) => {
      const textField = pdfForm.getTextField(key);
      let value = formData[key];
      if (key === 'contact') {
        const contact = billingContact ? {
          ...billingContact,
          address: data.billingAddress,
          city: data.billingCity,
          state: data.billingState,
          zip: data.billingZip,
        } : {
          ...primaryContact,
          address: data.address,
          city: data.city,
          state: data.state,
          zip: data.zip,
        };
        value = contact && `${contact.nameFirst} ${contact.nameLast}\n${client.name}\n${contact.address ? `${contact.address}\n` : ''}${contact.city ? `${contact.city}, ${contact.state} ${contact.zip}` : ''}`;
      } else if (key === 'date') {
        value = moment(invoiceFormData.date).format('MMMM D, YYYY');
      } else if (key === 'invoiceNumber') {
        value = `Invoice # ${value}`;
      }
      if (value) {
        textField.setText(schema[key]?.type === 'price' ? `$${formatNumber(value)}` : value);
        textField.acroField.setFlag(AcroFieldFlags.ReadOnly, true);
      }
    });
    const pdfBlob = new Blob([await pdfDoc.save()], { type: 'application/pdf;charset=utf-8' });
    saveAs(pdfBlob, `${campaignFormData.title} ${fileLabel} ${moment().format('M-D-YYYY')}.pdf`);
  };

  agreementSchema.agreementText.form.icons = [{
    type: iconType.PEN,
    tooltip: 'Populate Document',
    onClick: async ({ values: agreementFormData, setFieldValue }) => {
      const campaignFormData = campaignFormState.values;
      const [
        { data: { items: campaignItems } },
        { data: { items: campaignMarkets } },
        // { data: client },
      ] = await Promise.all([
        axios.get(`${CampaignItem.apiPath}?campaignId=${data.id}`),
        axios.get(`${CampaignMarket.apiPath}?campaignId=${data.id}`),
        // axios.get(`${Client.apiPath}/${campaignFormData.fk_clientId}`),
      ]);
      const campaignItem = last(campaignItems);
      const weeksOnDisplay = Math.round(campaignFormData.daysOnDisplay / 7);

      const placement =
        campaignFormData.type?.toLowerCase().includes('wndwposter') ? '• street-visible placement: WNDWposters™ hang in front of establishments, on doors, windows & in vestibules' :
        campaignFormData.type?.toLowerCase().includes('brochure') ? '• high-visibility placement: Brochures displayed in front doorways & on front counters' :
        campaignFormData.type?.toLowerCase().includes('postcard') ? '• high-visibility placement: Postcards displayed in front doorways & on front counters' :
        campaignFormData.type?.toLowerCase().includes('magazine') ? '• high-visibility placement: Magazines displayed in front doorways & on front counters' : '';
      const timeframe =
        campaignFormData.flatRate ? '• Flat Rate, installed only [1-2 weeks on display]' :
        campaignFormData.type?.toLowerCase().includes('wndwposter') ? `• ${weeksOnDisplay} weeks on display from full install date` :
        campaignFormData.drops ? `• ${campaignFormData.drops} drop(s): on display until all taken` : '';

      const size =
        campaignFormData.type?.toLowerCase().includes('wndwposter') ? 'WNDWposter™ Maximum Size:\n14x22 inches (308 sq inches), held vertically/portrait orientation.\nOversized, non-vertical/portrait oriented posters may incur surcharge.' :
        campaignFormData.type?.toLowerCase().includes('brochure') ? 'Brochure Maximum Size/Weight:\n6x9 inches (54 sq inches), 80# cover stock, two folds maximum.\nOversized, overweight brochures may incur surcharge.' :
        campaignFormData.type?.toLowerCase().includes('postcard') ? 'Postcard Maximum Size/Weight:\n6x9 inches (54 sq inches), 80# cover stock, no folds.\nOversized, overweight postcards may incur surcharge.' : '';

      const terms = '';
      /* const terms =
        campaignFormData.terms === 'Net 15' ? 'Advertiser agrees to remit total cost net 15 days from installation start date.' :
        campaignFormData.terms === 'Net 30' ? 'Advertiser agrees to remit total cost net 30 days from installation start date.' :
        campaignFormData.terms === 'First Time Client' ? 'As a first time client, Advertiser agrees to remit total cost prior to installation start date.' :
        campaignFormData.terms === 'Due Upon Receipt' ? 'Advertiser agrees to remit total cost prior to installation start date.' :
        campaignFormData.terms === '50% Deposit Net 30' ? 'Advertiser agrees to remit 50% of total cost prior to installation start date, and balance net 30 days from installation start date.' : ''; */

      const text = `
${campaignFormData.title?.toUpperCase()}
${campaignFormData.type ?? ''}


• ${campaignItem?.distributionGoal?.toLocaleString()} ${campaignFormData.type}s

• targeting the neighborhoods of ${campaignMarkets.map(({ market: { name } }) => name).join(', ')}:
${campaignMarkets.map(({ neighborhoods }) => neighborhoods?.map(({ name }) => name)).flat().join(', ')} & adjacent areas if needed

${placement ? `${placement}\n` : ''}
• PMD Venue Network: Independently Owned restaurants, cafes, salons, boutiques & storefronts

• installation scheduled to begin the week of ${moment(campaignFormData.dateStart).format('dddd, MMMM D, YYYY')}

• full installation anticipated within ${campaignFormData.installDays} business days

${timeframe ? `${timeframe}\n\n\n` : ''}
E-REPORTS:
• name, type & address of every display venue
• pictures of ${campaignFormData.type}s on site
• interactive venue mapping by market [beta]



Print Production:

Display: ${campaignItem?.distributionGoal?.toLocaleString()} total ${campaignFormData.type}s, ${campaignMarkets.length} market${campaignMarkets.length !== 1 ? 's' : ''}, ${weeksOnDisplay} weeks on display @

Total Cost ... $${formatNumber(agreementFormData.invoiceTotal, 2)}

${size ? `${size}\n\n\n` : ''}


${terms}`;

      setFieldValue('agreementText', text.trim());

      /*
              Upper(D1_Campaign::CampaignTitle) & "¶" &
              Proper(D1_CampaignItemJoin::c_Type) & "¶¶¶" &


              "• " & Case(Length(D1_CampaignItemJoin::DistributionGoal) = 4; Left(D1_CampaignItemJoin::DistributionGoal; 1) & "," & Right(D1_CampaignItemJoin::DistributionGoal; 3); D1_CampaignItemJoin::DistributionGoal)  & " " & D1_CampaignItemJoin::c_Type & "s" & "¶¶" &
              "• targeting the neighborhoods of " & D1_Campaign::c_Market & ":" & "¶" &
              Substitute (List(D1_CampaignMarketJoin::FK_Neighborhood); "¶"; ", ") & " & adjacent areas if needed" & "¶¶" &


              Case (PatternCount(D1_CampaignItemJoin::c_Type; "windowposter"); "• street-visible placement:  Windowposters™ hang in front of establishments, on doors, windows & in vestibules";
              PatternCount(D1_CampaignItemJoin::c_Type; "brochure"); "• high-visibility placement:  Brochures displayed in front doorways & on front counters";
              PatternCount(D1_CampaignItemJoin::c_Type; "postcard"); "• high-visibility placement: Postcards displayed in front doorways & on front counters";
              PatternCount(D1_CampaignItemJoin::c_Type; "aerplay"); "• in-store play:  Aerplay CDs are played for customers to hear";
              PatternCount(D1_CampaignItemJoin::c_Type; "magazine"); "• high-visibility placement:  Magazines displayed in front doorways & on front counters") & "¶¶" &

              "• PMD Venue Network: Independently Owned restaurants, cafes, salons, boutiques & storefronts" & "¶¶" &

              "• installation scheduled to begin the week of " & DayName ( D1_Campaign::DateStart ) & ", " & MonthName ( D1_Campaign::DateStart ) & " " & Day ( D1_Campaign::DateStart ) & ", " & Year ( D1_Campaign::DateStart ) & "¶¶" &

              "• full installation anticipated within " & D1_Campaign::InstallDays & " business days" & "¶¶" &

              Case (
                D1_Campaign::f_FlatRate = 1; "• Flat Rate, installed only [1-2 weeks on display]";
              PatternCount(D1_CampaignItemJoin::c_Type; "windowposter"); "• " & GetAsText (D1_Campaign::c_WeeksonDisplay) & " weeks on display from full install date";
              PatternCount(D1_CampaignItemJoin::c_Type; "aerplay"); "• " & D1_Campaign::AerplayWeeks & "play week(s)";
              "• " & D1_Campaign::Drops & " drop(s): on display until all taken") & "¶¶¶¶" &

              "E-REPORTS: " & "¶" &
              "• name, type & address of every display venue" & "¶" &
              "• pictures of " & Proper(D1_CampaignItemJoin::c_Type) & "s on site" & "¶" &
              "• interactive venue mapping by market [beta]" & "¶¶¶¶" &

              "Print Production: " & "¶¶" &

              "Display: " & Case(Length(D1_CampaignItemJoin::DistributionGoal) = 4; Left(D1_CampaignItemJoin::DistributionGoal; 1) & "," & Right(D1_CampaignItemJoin::DistributionGoal; 3); D1_CampaignItemJoin::DistributionGoal)  & " total " & Proper(D1_CampaignItemJoin::c_Type) & "s, " & D1_Campaign::c_CountMarket & " market(s)," & "¶" &
              D1_Campaign::c_WeeksonDisplay & " weeks on display @ $" & "¶¶" &

              "Total Cost ... $" & D1_Campaign::InvoiceTotal & "¶¶" &

              TextSize(
                Case (
                  PatternCount(D1_CampaignItemJoin::c_Type; "windowposter"); "Windowposter™ Maximum Size:" & "¶" & "14x22 inches (308 sq inches), held vertically/portrait orientation." & "¶" & "Oversized, non-vertical/portrait oriented posters may incur surcharge." & "¶¶";
              PatternCount(D1_CampaignItemJoin::c_Type; "brochure"); "Brochure Maximum Size/Weight:" & "¶" & "6x9 inches (54 sq inches), 80# cover stock, two folds maximum." & "¶" & "Oversized, overweight brochures may incur surcharge." & "¶¶";
              PatternCount(D1_CampaignItemJoin::c_Type; "postcard"); "Postcard Maximum Size/Weight:" & "¶" & "6x9 inches (54 sq inches), 80# cover stock, no folds." & "¶" & "Oversized, overweight postcards may incur surcharge." & "¶¶"
            ) &
              D1_Campaign::c_Terms; 7)
      */
    },
  }, {
    type: iconType.EXPORT,
    onClick: async () => exportDoc('agreement'),
  }];

  invoiceSchema.invoiceText.form.icons = [{
    type: iconType.PEN,
    tooltip: 'Populate Document',
    onClick: async ({ values: invoiceFormData, setFieldValue }) => {
      const campaignFormData = campaignFormState.values;
      const [
        { data: { items: campaignItems } },
        { data: { items: campaignMarkets } },
        // { data: client },
      ] = await Promise.all([
        axios.get(`${CampaignItem.apiPath}?campaignId=${data.id}`),
        axios.get(`${CampaignMarket.apiPath}?campaignId=${data.id}`),
      ]);
      const campaignItem = last(campaignItems);
      const weeksOnDisplay = Math.round(campaignFormData.daysOnDisplay / 7);

      const agreementFormData = agreementFormState.values;
      const text = `
please make check payable to:
PMD Promotion
19 West 21st Street, Suite 901
New York, NY  10010






• ${campaignItem?.distributionGoal?.toLocaleString()} ${campaignFormData.type}s
• targeting the neighborhoods of ${campaignMarkets.map(({ market: { name } }) => name).join(', ')}
• installation scheduled to begin the week of ${moment(campaignFormData.dateStart).format('dddd, MMMM D, YYYY')}

${campaignItem?.distributionGoal?.toLocaleString()} total ${campaignFormData.type}s, ${campaignMarkets.length} market${campaignMarkets.length !== 1 ? 's' : ''}, ${weeksOnDisplay} weeks on display @

Total Cost ... $${formatNumber(agreementFormData.invoiceTotal, 2)}
`;

      setFieldValue('invoiceText', text.trim());

      const terms =
        campaignFormData.terms === 'Net 15' ? 'Advertiser agrees to remit total cost net 15 days from installation start date.' :
        campaignFormData.terms === 'Net 30' ? 'Advertiser agrees to remit total cost net 30 days from installation start date.' :
        campaignFormData.terms === 'First Time Client' ? 'As a first time client, Advertiser agrees to remit total cost prior to installation start date.' :
        campaignFormData.terms === 'Due Upon Receipt' ? 'Advertiser agrees to remit total cost prior to installation start date.' :
        campaignFormData.terms === '50% Deposit Net 30' ? 'Advertiser agrees to remit 50% of total cost prior to installation start date, and balance net 30 days from installation start date.' : '';
      setFieldValue('invoiceTerms', terms);

      /* "please make check payable to:¶PMD Promotion¶19 West 21st Street, Suite 901¶New York, NY  10010¶¶¶¶¶¶¶" &

      "• " & Case(Length(D1_CampaignItemJoin::DistributionGoal) = 4; Left(D1_CampaignItemJoin::DistributionGoal; 1) & "," & Right(D1_CampaignItemJoin::DistributionGoal; 3); D1_CampaignItemJoin::DistributionGoal) & " " & Upper(D1_Campaign::CampaignTitle) & " " & Proper(D1_CampaignItemJoin::c_Type) & "s" & "¶" &
      "• targeting the neighborhoods of " & D1_Campaign::c_Market & "¶" &
      "• installation scheduled to begin the week of " & DayName (D1_Campaign::DateStart ) & ", " & MonthName ( D1_Campaign::DateStart ) & " " & Day ( D1_Campaign::DateStart ) & ", " & Year ( D1_Campaign::DateStart ) & "¶¶" &

      Case(Length(D1_CampaignItemJoin::DistributionGoal) = 4; Left(D1_CampaignItemJoin::DistributionGoal; 1) & "," & Right(D1_CampaignItemJoin::DistributionGoal; 3); D1_CampaignItemJoin::DistributionGoal)  & " total " & Proper(D1_CampaignItemJoin::c_Type) & "s, " & D1_Campaign::c_CountMarket & " market(s)," & "¶" &
      D1_Campaign::c_WeeksonDisplay & " weeks on display @ $" & "¶¶" &

      "Total Cost ... $" & D1_Campaign::InvoiceTotal */
    },
  }, {
    type: iconType.EXPORT,
    onClick: async () => exportDoc('invoice'),
  }];

  return (
    <div>
      <Row>
        <Col>
          <Form
            apiPath={apiPath}
            schema={agreementSchema}
            data={data}
            getState={(state) => setAgreementFormState(state)}
          />
        </Col>
        <Col>
          <Form
            apiPath={apiPath}
            schema={invoiceSchema}
            data={data}
            getState={(state) => setInvoiceFormState(state)}
          />
        </Col>
      </Row>
    </div>
  );
};

const Report = ({ detailData, exportVenues } = {}) => {
  const isClient = _isClient();
  const reportContentRef = useRef(null);
  const reportContentWidth = reportContentRef?.current?.getBoundingClientRect().width || 0;
  const reportExportWidth = 1250;
  const reportExportHeight = 550;
  const [ isReportShowing, setIsReportShowing ] = useState(false);
  const [ isReportLoading, setIsReportLoading ] = useState(true);
  const [ reportJobs, setReportJobs ] = useState([]);
  const [ reportJobActive, setReportJobActive ] = useState(null);
  const reportPhotosRef = useRef(null);
  const [ reportPhotos, setReportPhotos ] = useState(null);
  const [ reportPhotoData, setReportPhotoData ] = useState({});
  const [ reportPhotoModal, setReportPhotoModal ] = useState(null);
  const reportPhotoRef = useRef(null);
  const [ reportPhotosActive, setReportPhotosActive ] = useState([]);
  const reportPhotosActiveRef = useRef(null);
  const [ isReportExportLoading, setIsReportExportLoading ] = useState(true);
  const [ isReportExporting, setIsReportExporting ] = useState(false);
  const [ reportPhotoExportCounter, setReportPhotoExportCounter ] = useState(null);
  const [ reportNestedHeight, setReportNestedHeight ] = useState(null);
  const [ reportMap, setReportMap ] = useState(null);
  const reportMapRef = useRef(null);
  const [ reportMapMarkers, setReportMapMarkers ] = useState(null);
  const [ reportMapInfoWindow, setReportMapInfoWindow ] = useState(null);
  const [ isReportMapTileLoading, setIsReportMapTileLoading ] = useState(false);
  const [ reportNeighborhoodChartData, setReportNeighborhoodChartData ] = useState(null);
  const [ reportNeighborhoodChart, setReportNeighborhoodChart ] = useState(null);
  const [ reportZipMap, setReportZipMap ] = useState(null);
  const reportZipMapRef = useRef(null);
  const [ reportZipMapMarkers, setReportZipMapMarkers ] = useState(null);
  const [ reportZipMapInfoWindow, setReportZipMapInfoWindow ] = useState(null);
  const [ reportZipChartData, setReportZipChartData ] = useState(null);
  const [ reportZipChartImage, setReportZipChartImage ] = useState(null);
  const [ reportVenuesCountWidth, setReportVenuesCountWidth ] = useState(null);
  const reportCoverLogoRef = useRef(null);

  const reportColors = [
    '#3366cc',
    '#dc3912',
    '#ff9900',
    '#109618',
    '#990099',
    '#0099c6',
    '#dd4477',
    '#66aa00',
    '#b82e2e',
    '#316395',
    '#994499',
    '#22aa99',
    '#aaaa11',
    '#6633cc',
    '#e67300',
    '#8b0707',
    '#651067',
    '#329262',
    '#5574a6',
    '#3b3eac',
    '#b77322',
    '#16d620',
    '#b91383',
    '#f4359e',
    '#9c5935',
    '#a9c413',
    '#2a778d',
    '#668d1c',
    '#bea413',
    '#0c5922',
    '#743411',
  ];

  useEffect(async () => {
    const { data: { items: jobs } } = await axios.get(`${Job.apiPath}/?campaignId=${detailData.id}`);
    setReportJobs(jobs);
    setReportJobActive(jobs[0]);
  }, []);

  const queue = new PQueue({ concurrency: 50 });
  const fetchPhoto = async ({ photo, mounted = true }) => {
    const id = photo.id;
    if (photo.googleCloudUrl) {
      await queue.add(() => mounted && fetch(photo.googleCloudUrl, { cache: 'no-cache' })
        .then((response) => response.blob())
        .then((blob) => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            photo.photo = reader.result.split(',')[1];
            resolve();
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })));
    } else {
      ({ data: photo } = await queue.add(() => mounted ? axios.get(`${photoApiPath}/${id}?fields=id,photo,date,time,flagApproved`) : {}));
    }

    if (mounted) {
      reportPhotoData[id] = photo;
      setReportPhotoData({
        ...reportPhotoData,
      });
    }
    return photo;
  };

  const fetchPhotoSaved = async ({ photoSaved, mounted = true }) => {
    ({ data: photoSaved } = await queue.add(() => axios.get(`${CampaignReportPhoto.apiPath}/${photoSaved.id}`)));
    return photoSaved;
  };

  const calcReportPhotoCrop = (img) => {
    const { width, height } = img;
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        16 / 9,
        width,
        height,
      ),
      width,
      height,
    );
  };

  const resizeImage = ({ canvas, maxWidth, maxHeight }) =>
    new Promise((resolve) => {
      const img = new Image();
      img.src = canvas.toDataURL('image/png');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) { // eslint-disable-line
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
    });

  useEffect(() => {
    let mounted = true;
    if (reportJobActive) {
      (async () => {
        setIsReportLoading(true);
        setIsReportExportLoading(true);
        const venues = [];
        const { data: { items: photos } } = await axios.get(`${photoApiPath}/?campaignId=${detailData.id}&jobId=${reportJobActive.id}`);
        const { data: { items: photosSaved } } = await axios.get(`${CampaignReportPhoto.apiPath}/?campaignId=${detailData.id}&jobId=${reportJobActive.id}`);
        let photosActive = orderBy(photos.map((photo) => ({
          ...photo,
          position: photosSaved.find((photoSaved) => photoSaved.fk_photoId === photo.id)?.position,
        })).filter(({ position }) => typeof position !== 'undefined'), [ 'position', 'asc' ]);
        setReportPhotosActive(photosActive);
        setIsReportLoading(false);

        await Promise.all(photosActive.map(async (photo) => {
          const id = photo.id;
          photo = await fetchPhoto({ photo, mounted });
          let photoSaved = photosSaved.find((photoSaved) => photoSaved.fk_photoId === id);
          if (photoSaved) {
            photoSaved = await fetchPhotoSaved({ photoSaved, mounted });
            photosActive = photosActive.filter((photoActive) => photoActive.id !== id);
            const photoActive = {
              ...photo,
              position: photoSaved.position,
              ...photoSaved.width && {
                crop: {
                  width: photoSaved.width,
                  height: photoSaved.height,
                  x: photoSaved.x,
                  y: photoSaved.y,
                  photo: photoSaved.photo,
                },
                scale: photoSaved.scale,
              },
            };
            photosActive.push(photoActive);

            setReportPhotosActive(orderBy(photosActive, [ 'position', 'asc' ]));
          }
        })); // fetch active photos first

        photos.map(async (photo) => {
          const venue = photo.venue;
          if (!venues.some(({ id }) => venue.id === id)) {
            venues.push(venue);
            venue.photos = photos.filter((photo) => photo.venue.id === venue.id);
          }
        });
        setReportPhotos(venues.reduce((acc, val) =>
          [ ...acc, ...val.photos.map((photo, i) => ({ ...photo, venuePhotoIndex: i })) ], []));

        const neighborhoods = photosActive.reduce((acc, { venue }) => {
          if (venue.neighborhood) {
            const neighborhood = venue.neighborhood.name;
            acc[neighborhood] = {
              zipCodes: [
                ...new Set([
                  ...acc[neighborhood]?.zipCodes || [],
                  venue.zip && venue.zip.trim(),
                ].filter(Boolean)),
              ],
              count: (acc[neighborhood]?.count || 0) + 1,
            };
          }
          return acc;
        }, {});

        let chartData = [
          [
            'Neighborhood',
            'Venues',
            { role: 'style' },
          ],
        ];
        Object.keys(neighborhoods).forEach((key, i) => {
          const { count, zipCodes } = neighborhoods[key];
          chartData.push([
            `${key}: ${zipCodes.join(', ')}`,
            count,
            reportColors[i],
          ]);
        });
        setReportNeighborhoodChartData(chartData);

        const zips = photosActive.reduce((acc, { venue }) => {
          if (venue.zip) {
            acc[venue.zip] = {
              count: (acc[venue.zip]?.count || 0) + 1,
            };
          }
          return acc;
        }, {});

        chartData = [
          [ 'Zip', 'Venues' ],
        ];
        Object.keys(zips).forEach((zip, i) => {
          const { count } = zips[zip];
          chartData.push([
            zip,
            count,
          ]);
        });
        setReportZipChartData(chartData);
        setIsReportExportLoading(false);
      })();
    }

    return () => {
      mounted = false;
    };
  }, [reportJobActive]);

  const exportReport = async () => {
    setIsReportExporting(true);
    setReportPhotoExportCounter(null);
    const pdfDoc = await PDFDocument.load(await fetch(reportPdf).then((res) => res.arrayBuffer()));
    const pdfForm = pdfDoc.getForm();
    const pdfLastPage = pdfDoc.getPageCount() - 1;
    pdfDoc.registerFontkit(fontkit);
    const montserratBoldFontBytes = await fetch(montserratBoldFont).then((res) => res.arrayBuffer());
    const montserratSemiBoldFontBytes = await fetch(montserratSemiBoldFont).then((res) => res.arrayBuffer());
    const montserratRegularFontBytes = await fetch(montserratRegularFont).then((res) => res.arrayBuffer());

    const coverTitleField = pdfForm.getTextField('coverTitle');
    coverTitleField.setText(detailData.title);
    coverTitleField.defaultUpdateAppearances(await pdfDoc.embedFont(montserratBoldFontBytes, { subset: true }));

    const logo = detailData.logo || detailData.client?.logo;
    if (logo) {
      pdfForm.getButton('coverLogoImage').setImage(await pdfDoc.embedPng(logo));
    }

    const reportPages = [];
    const reportZip = new JSZip();
    const reportZipPhotosFolder = reportZip.folder('photos');

    reportPhotosActive.map((photo, i) => photo.position = i); // eslint-disable-line
    await Promise.all(chunk(reportPhotosActive, 1).map(async (photos) => {
      const saved = await pdfDoc.saveAsBase64();
      const pdfDocPhotoPage = await PDFDocument.load(saved);
      const pdfFormPhotoPage = pdfDocPhotoPage.getForm();
      pdfDocPhotoPage.registerFontkit(fontkit);
      // pdfFormPhotoPage.getTextField('jobName').setText(`${reportJobActive?.item.name} / ${reportJobActive?.campaignMarket.market.name}`);
      await Promise.all(photos.map(async ({ photo, crop, scale, venue, position }, i) => {
        if (crop) {
          await reportZipPhotosFolder.file(`${reportJobActive?.campaignMarket.market.name} - ${position + 1}.jpg`, crop.photo, { base64: true });
          const photoField = pdfFormPhotoPage.getButton('photo');
          photoField.setImage(await pdfDocPhotoPage.embedJpg(crop.photo), ImageAlignment.Left);
        }

        const campaignTitleField = pdfFormPhotoPage.getTextField('campaignTitle');
        campaignTitleField.setText(detailData.title);
        campaignTitleField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratBoldFontBytes, { subset: true }));

        const venueNameField = pdfFormPhotoPage.getTextField('venueName');
        venueNameField.setText(venue.name);
        venueNameField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratRegularFontBytes, { subset: true }));
        const venueTypeField = pdfFormPhotoPage.getTextField('venueType');
        venueTypeField.setText(venue.type?.type || '-');
        venueTypeField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratRegularFontBytes, { subset: true }));

        const venueNeighborhoodField = pdfFormPhotoPage.getTextField('venueNeighborhood');
        venueNeighborhoodField.setText(venue.neighborhood?.name ? `${venue.neighborhood.name}` : '-');
        venueNeighborhoodField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratRegularFontBytes, { subset: true }));

        const venueLocationField = pdfFormPhotoPage.getTextField('venueLocation');
        venueLocationField.setText(`${venue.city}, ${venue.state} ${venue.zip}`);
        venueLocationField.defaultUpdateAppearances(await pdfDocPhotoPage.embedFont(montserratRegularFontBytes, { subset: true }));

        setReportPhotoExportCounter(position + 1);
      }));
      /* if (photos.length === 1) {
        pdfFormPhotoPage.removeField(pdfFormPhotoPage.getButton('photo2'));
        pdfFormPhotoPage.removeField(pdfFormPhotoPage.getTextField('location2'));
      } */


      const venue = photos[0].venue;
      const venueLocationRect = pdfFormPhotoPage.getTextField('venueLocation').acroField.getWidgets()[0].getRectangle();

      pdfFormPhotoPage.flatten();
      const [photoPage] = await pdfDoc.copyPages(pdfDocPhotoPage, [pdfLastPage]);

      const link = photoPage.doc.context.register(
        photoPage.doc.context.obj({
          Type: 'Annot',
          Subtype: 'Link',
          Rect: [ venueLocationRect.x, venueLocationRect.y - 20, venueLocationRect.x + venueLocationRect.width, venueLocationRect.y + venueLocationRect.height ],
          Border: [ 0, 0, 0 ],
          C: [ 0, 0, 0 ],
          A: {
            Type: 'Action',
            S: 'URI',
            URI: PDFString.of(venue.googlePlaceId ? `https://www.google.com/maps/place/?q=place_id:${venue.googlePlaceId}` : `https://maps.google.com/?q=${encodeURIComponent(`${venue.name},${venue.streetNumber} ${venue.streetName},${venue.city},${venue.state}`)}`),
          },
        }),
      );
      photoPage.node.set(PDFName.of('Annots'), pdfDoc.context.obj([link]));

      reportPages.push(photoPage);
      // pdfDoc.addPage(copied);
    }));
    reportPages.forEach((page) => pdfDoc.addPage(page));

    const mapTitleField = pdfForm.getTextField('mapTitle');
    mapTitleField.setText(`${reportJobActive?.campaignMarket.market.name} - Locations`);
    mapTitleField.defaultUpdateAppearances(await pdfDoc.embedFont(montserratBoldFontBytes, { subset: true }));
    const map = await html2canvas(reportMapRef.current, { backgroundColor: null, allowTaint: true, useCORS: true });
    const mapImageField = pdfForm.getButton('mapImage');
    mapImageField.setImage(await pdfDoc.embedPng(await resizeImage({ canvas: map, maxWidth: reportExportWidth * 2, maxHeight: reportExportHeight * 2 })));
    const mapImageWidget = mapImageField.acroField.getWidgets()[0];
    const mapImageRect = mapImageWidget.getRectangle();
    const mapImageLink = pdfDoc.context.register(
      pdfDoc.context.obj({
        Type: 'Annot',
        Subtype: 'Link',
        Rect: [ mapImageRect.x, mapImageRect.y, mapImageRect.x + mapImageRect.width, mapImageRect.y + mapImageRect.height ],
        Border: [ 0, 0, 0 ],
        C: [ 0, 0, 0 ],
        A: {
          Type: 'Action',
          S: 'URI',
          URI: PDFString.of(`${document.location.href}?auth=${detailData.authKey}`),
        },
      }),
    );
    const mapImagePage = pdfDoc.getPages().find((p) => p.ref === mapImageWidget.P());
    mapImagePage.node.lookup(PDFName.of('Annots'), PDFArray).push(mapImageLink);

    const neighborhoodTitleField = pdfForm.getTextField('neighborhoodTitle');
    neighborhoodTitleField.setText(`${reportJobActive?.campaignMarket.market.name} - Neighborhoods`);
    neighborhoodTitleField.defaultUpdateAppearances(await pdfDoc.embedFont(montserratBoldFontBytes, { subset: true }));
    const reportNeighborhoodChartImage = reportNeighborhoodChart.getImageURI();
    pdfForm.getButton('neighborhoodChartImage').setImage(await pdfDoc.embedPng(reportNeighborhoodChartImage));

    const zipTitleField = pdfForm.getTextField('zipTitle');
    zipTitleField.setText(`${reportJobActive?.campaignMarket.market.name} - Zips`);
    zipTitleField.defaultUpdateAppearances(await pdfDoc.embedFont(montserratBoldFontBytes, { subset: true }));

    const zipMap = await html2canvas(reportZipMapRef.current, { backgroundColor: null, allowTaint: true, useCORS: true });
    pdfForm.getButton('zipMapImage').setImage(await pdfDoc.embedPng(zipMap.toDataURL('image/png')));
    pdfForm.getButton('zipChartImage').setImage(await pdfDoc.embedPng(reportZipChartImage));

    pdfDoc.removePage(pdfLastPage);
    pdfForm.getFields().forEach((f) => f.enableReadOnly());
    pdfDoc.setTitle(`${detailData.title} Report`);
    const pdfBlob = new Blob([await pdfDoc.save()], { type: 'application/pdf;charset=utf-8' });
    await reportZip.file(`${detailData.title} - Report - ${moment().format('MMDDYYYY')}.pdf`, pdfBlob);
    await exportVenues({ zip: reportZip, job: reportJobActive });
    const zipBlob = await reportZip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, `${detailData.title} - Report - ${moment().format('MMDDYYYY')}.zip`);
    setIsReportExporting(false);
  };

  const exportIcon = {
    type: iconType.EXPORT,
    onClick: exportReport,
    // ... isClient && { label: 'Export All Assets' },
    tooltip: 'Export All Assets',
    exportingMsg: `Exporting${reportPhotoExportCounter ? ` ${reportPhotoExportCounter} of ${reportPhotosActive.length} ` : ''}...`,
    isLoading: isReportExportLoading,
  };

  const changeReportPhoto = async (photo) => {
    if (isClient) {
      return;
    }

    setReportPhotosActive((reportPhotosActive) =>
      reportPhotosActive.map((item) => item.id === photo.id ? photo : item));

    if (reportPhotoModal) {
      const { data: photoSaved } = await axios.put(`${CampaignReportPhoto.apiPath}/${reportPhotoModal.id}`, { ...reportPhotoModal.crop, scale: reportPhotoModal.scale, photo: undefined });
      const photoActive = reportPhotosActive.find((item) => item.id === photoSaved.fk_photoId);
      if (photoActive) {
        photoActive.crop = photoSaved;
        setReportPhotosActive([...reportPhotosActive]);
      }
    }
  };

  const onReportRowsRendered = ({ startIndex, stopIndex }) => {
    Promise.all(
      range(startIndex, stopIndex + 1)
        .map((index) => reportPhotos[index])
        .filter((photo) => !reportPhotosActive.some((photoActive) => photoActive.id === photo.id) && photo.fk_jobId === reportJobActive.id)
        .map((photo) => !reportPhotoData[photo.id] && fetchPhoto({ photo })),
    );
  };
  const debounceReportRowsRendered = useCallback(debounce(onReportRowsRendered, 500), [ reportPhotos, reportPhotosActive, reportPhotoData, reportJobActive ]);
  const getReportRowHeight = ({ index }) => !index ? 180 : !reportPhotos[index].venuePhotoIndex ? 220 : 140;

  /* Map */

  const { isLoaded: isMapLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places'],
  });

  const onMapLoad = useCallback(async (map) => {
    setReportMap(map);
  }, []);

  const onMapUnmount = useCallback(() => {
    setReportMap(null);
  }, []);

  useEffect(() => {
    if (isMapLoaded) {
      setReportMapInfoWindow(new google.maps.InfoWindow());
    }
  }, [isMapLoaded]);

  const setMapMarkerHover = ({ venue, marker, hover = true }) => {
    marker = marker || reportMapMarkers?.find((marker) => marker.venue.id === venue.id);
    if (hover) {
      reportMapInfoWindow.setContent(`
      <div class="infowindow">
        <h1>${venue.name}</h1>
        <div>${venue.streetNumber} ${venue.streetName}</div>
      </div>`);
      reportMapInfoWindow.open({
        map: reportMap,
        anchor: marker,
        shouldFocus: false,
      });
      // if (reportMap.getZoom() < 17) {
      //   reportMap.setZoom(17);
      // }
    } else {
      reportMapInfoWindow.close();
    }
  };

  const onMapRowMouseOver = ({ node: { data: venue } }) =>
    setMapMarkerHover({ venue });

  const onMapRowMouseOut = ({ node: { data: venue } }) =>
    setMapMarkerHover({ venue, hover: false });

  useEffect(async () => {
    if (reportMap && !isReportShowing) {
      const bounds = new google.maps.LatLngBounds();
      if (reportMapMarkers) {
        reportMapMarkers.forEach((marker) => {
          marker.setMap(null);
        });
      }
      if (reportPhotosActive.length) {
        const markers = reportPhotosActive.map(({ venue }) => {
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(venue.latitude, venue.longitude),
            // animation: google.maps.Animation.DROP,
            icon: {
              url: reportMapMarkerImage,
              scaledSize: new google.maps.Size(30, 40),
            },
            venue,
            map: reportMap,
          });
          marker.addListener('click', async () => {
            if (venue.googlePlaceId) {
              window.open(`https://www.google.com/maps/place/?q=place_id:${venue.googlePlaceId}`, '_blank');
            }
          });
          marker.addListener('mouseover', () => {
            setMapMarkerHover({ venue, marker });
          });
          marker.addListener('mouseout', () => {
            setMapMarkerHover({ venue, marker, hover: false });
          });

          bounds.extend(marker.position);
          return marker;
        });
        setReportMapMarkers(markers);
        fitBounds({ map: reportMap, bounds, maxZoom: 17 });
      }
    }
  }, [ reportMap, isReportShowing ]);


  const onReportZipMapLoad = useCallback(async (map) => {
    setReportZipMap(map);
  }, []);

  const onReportZipMapUnmount = useCallback(() => {
    setReportZipMap(null);
  }, []);

  useEffect(() => {
    if (isMapLoaded) {
      setReportZipMapInfoWindow(new google.maps.InfoWindow());
    }
  }, [isMapLoaded]);

  const setReportZipMapMarkerHover = ({ zip, count, marker, hover = true }) => {
    marker = marker || reportZipMapMarkers?.find((marker) => marker.zip === zip);
    if (hover) {
      reportZipMapInfoWindow.setContent(`
      <div class="infowindow">
        <h1>${zip}</h1>
        <div><span>${count}</span> venue${count !== 1 ? 's' : ''}</div>
      </div>`);
      reportZipMapInfoWindow.open({
        map: reportZipMap,
        anchor: marker,
        shouldFocus: true,
      });
    } else {
      reportZipMapInfoWindow.close();
    }
  };


  useEffect(async () => {
    if (reportZipMap && !isReportShowing) {
      const bounds = new google.maps.LatLngBounds();
      if (reportZipMapMarkers) {
        reportZipMapMarkers.forEach((marker) => {
          marker.setMap(null);
        });
      }
      if (reportPhotosActive.length) {

        const zipVenues = Object.values(reportPhotosActive.map(({ venue }) => venue).reduce((acc, venue) => {
          const { zip } = venue;
          if (zip) {
            if (!acc[zip]) {
              acc[zip] = [];
            }
            acc[zip].push(venue);
          }
          return acc;
        }, {}));

        const min = _.min(zipVenues.map((venues) => venues.length));
        const max = _.max(zipVenues.map((venues) => venues.length));
        const markers = _.orderBy(zipVenues.map((venues, i) => ({
            latitude: venues.reduce((sum, v) => sum + v.latitude, 0) / venues.length,
            longitude: venues.reduce((sum, v) => sum + v.longitude, 0) / venues.length,
            count: venues.length,
            color: reportColors[i],
            size: (min === max ? 50 : Math.max(((venues.length - min) * 50) / (max - min), 7)),
            zip: venues[0].zip,
          })), 'count', 'desc')
          .map(({ latitude, longitude, count, color, size, zip }, i) => {
            const marker = new google.maps.Marker({
              position: new google.maps.LatLng(latitude, longitude),
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: color,
                fillOpacity: 0.9,
                scale: size,
                strokeWeight: 8,
                strokeOpacity: 0.2,
                strokeColor: color,
              },
              zIndex: Number(google.maps.Marker.MAX_ZINDEX) + i,
              map: reportZipMap,
              zip,
              // label: {
              //   text: String(count),
              //   color: "rgba(255,255,255,0.9)",
              //   fontSize: "12px",
              // },
            });

            marker.addListener('mouseover', () => {
              setReportZipMapMarkerHover({ zip, count, marker });
            });
            marker.addListener('mouseout', () => {
              setReportZipMapMarkerHover({ zip, count, marker, hover: false });
            });

            bounds.extend(marker.position);
            return marker;
          });
        setReportZipMapMarkers(markers);
        fitBounds({ map: reportZipMap, bounds, maxZoom: 17 });
      }
    }
  }, [ reportZipMap, isReportShowing ]);

  return (
    <>
      <Header
        label={`${detailData?.title} Report`}
        icons={isReportLoading ? [] : [
          !isClient && {
            type: iconType.GEAR,
            detail: true,
            tooltip: 'Configure Campaign Report',
            onClick: () => setIsReportShowing(true),
          },
          exportIcon,
        ].filter(Boolean)}
        className={[ 'has-export', !isClient && 'has-configure' ].filter(Boolean).join(' ')}
      />
      {isReportLoading ? <Spinner /> :
        <div className={classNames('content', { exporting: isReportExporting })} ref={reportContentRef}>
           <Tabs
             tabs={[
               {
                 key: 'map',
                 title: 'Map',
               },
               {
                 key: 'photos',
                 title: 'Photos',
                 content:
                   !reportPhotosActive.length ? <Alert variant="secondary" className="empty">No photos</Alert> :
                     <SortableList
                       onSortEnd={(oldIndex, newIndex) => {
                         const sorted = arrayMove(reportPhotosActive, oldIndex, newIndex);
                         setReportPhotosActive(sorted);
                         axios.post(CampaignReportPhoto.apiPath, { campaignId: detailData.id, jobId: reportJobActive.id, reposition: sorted.map(({ id }, i) => ({ fk_photoId: id, position: i })) });
                       }}
                       className="photos"
                       // draggedItemClassName="campaign report-select photo drag"
                     >
                       {reportPhotosActive.map((photoData, i) =>
                         <SortableItem key={photoData.id}>
                           <div className={classNames('photo slide', { loading: !photoData.photo })}>
                             <div className="col">
                               <div>
                                 <div className="label">
                                   Venue:
                                 </div>
                                 {photoData.venue.name}
                               </div>
                               <div>
                                 <div className="label">
                                   Type:
                                 </div>
                                 {photoData.venue.type?.type || '-'}
                               </div>
                               <div>
                                 <div className="label">
                                   Neighborhood:
                                 </div>
                                 {`${photoData.venue.neighborhood?.name ? `${photoData.venue.neighborhood.name}, ` : ''}${photoData.venue.market?.name}`}
                               </div>
                               <div className="location">
                                 <div className="label">
                                   Location:
                                 </div>
                                 <a href={photoData.venue.googlePlaceId ? `https://www.google.com/maps/place/?q=place_id:${photoData.venue.googlePlaceId}` : `https://maps.google.com/?q=${encodeURIComponent(`${photoData.venue.name},${photoData.venue.streetNumber} ${photoData.venue.streetName},${photoData.venue.city},${photoData.venue.state}`)}`} target="_blank" rel="noreferrer">
                                   {photoData.venue.city}, {photoData.venue.state} {photoData.venue.zip}
                                   <div>
                                     <img
                                       src={reportMapMarkerImage}
                                     />
                                   </div>
                                 </a>
                               </div>
                             </div>
                             <div className="col">
                               <div className="selected">
                                 {!photoData?.crop?.photo ?
                                   <>
                                     <FontAwesomeIcon icon={faImage} />
                                     <FontAwesomeIcon icon={faSquare} />
                                     <Spinner />
                                   </> :
                                   <div>
                                     {photoData.crop.photo && <img // eslint-disable-line
                                       src={`data:image/jpg;base64,${photoData.crop.photo}`}
                                       draggable={false}
                                     />}
                                   </div>}
                               </div>
                             </div>
                           </div>
                         </SortableItem>)}
                     </SortableList>,
               },
               {
                 key: 'venues',
                 title: 'Venues',
                 content:
                   <Venue
                     dataParent={detailData}
                     modelParent={model}
                     apiParams={{ distributionVenue: true, jobId: reportJobActive?.id }}
                     readOnly={isClient}
                     showAdd={false}
                     showMap={false}
                     showExport={false}
                     onInit={() => setReportVenuesCountWidth(document.querySelector('.tab-pane.venues .header .count')?.getBoundingClientRect()?.width || 0)}
                   />,
               },
               {
                 key: 'neighborhoods',
                 title: 'Neighborhoods',
                 content:
                   !reportNeighborhoodChartData || !reportPhotosActive.length ? <Alert variant="secondary" className="empty">No data</Alert> :
                   <div className="neighborhood slide">
                     <div className="title">
                       {reportJobActive?.campaignMarket.market.name} - Neighborhoods
                     </div>
                   </div>,
               },
               {
                 key: 'zips',
                 title: 'Zips',
                 content:
                   !reportZipChartData || !reportPhotosActive.length ? <Alert variant="secondary" className="empty">No data</Alert> :
                     <div className="zip slide">
                       <div className="title">
                         {reportJobActive?.campaignMarket.market.name} - Zips
                       </div>
                     </div>,
               },
             ]}
           />
           {!isReportLoading && reportJobs.length > 1 && !isReportExporting &&
             <Dropdown className="job" style={{ marginRight: reportVenuesCountWidth }}>
               <Dropdown.Toggle variant="outline-secondary">{`${reportJobActive?.item.name}${reportJobActive?.campaignMarket ? ` / ${reportJobActive.campaignMarket.market.name}` : ''}`}</Dropdown.Toggle>
               <Dropdown.Menu>
                 {reportJobs?.map((job, i) => (
                   <React.Fragment key={i}>
                     <Dropdown.Item
                       className={classNames({ active: job.id === reportJobActive?.id })}
                       onClick={() => {
                         setReportJobActive(job);
                         setIsReportLoading(true);
                         setReportPhotos(null);
                       }}
                     >
                       {`${job.item.name}${job.campaignMarket ? ` / ${job.campaignMarket.market.name}` : ''}`}
                     </Dropdown.Item>
                   </React.Fragment>
                 ))}
               </Dropdown.Menu>
             </Dropdown>}
           {reportNeighborhoodChartData &&
           <div className="chart-container neighborhood">
             <div className="chart">
               <Chart
                 chartType="ColumnChart"
                 data={reportNeighborhoodChartData}
                 width={isReportExporting ? reportExportWidth : reportContentWidth * 0.95}
                 height={reportExportHeight}
                 options={{
                   hAxis: {
                     textStyle: {
                       fontSize: 12,
                     },
                   },
                   vAxis: {
                     title: 'Venues',
                     textStyle: {
                       fontSize: 12,
                     },
                   },
                   chartArea: {
                     width: '100%',
                     height: '100%',
                     left: 80,
                     top: 10,
                     bottom: 80,
                   },
                   fontName: 'Montserrat',
                   fontSize: 13,
                   is3D: true,
                   backgroundColor: { fill: 'transparent' },
                   legend: {
                     position: 'none',
                   },
                   tooltip: {
                     ignoreBounds: true,
                     textStyle: {
                       fontSize: 12,
                     },
                   },
                 }}
                 chartEvents={[
                   {
                     eventName: 'ready',
                     callback: (c) => {
                       setReportNeighborhoodChart(c.chartWrapper.getChart());
                     },
                   },
                 ]}
               />
             </div>
           </div>}
          {reportZipChartData &&
            <div className="chart-container zip">
              <div className="map" ref={reportZipMapRef}>
                <GoogleMap
                  mapContainerStyle={{
                    width: Math.max(reportContentWidth, reportExportWidth) * 0.575,
                    height: reportExportHeight,
                  }}
                  options={{
                    disableDefaultUI: isReportExporting,
                    controlSize: 30,
                    cameraControl: false,
                    zoomControl: true,
                    gestureHandling: 'greedy',
                    styles: [
                      {
                        featureType: 'poi',
                        stylers: [
                          {
                            visibility: 'off',
                          },
                        ],
                      },
                    ],
                  }}
                  onLoad={onReportZipMapLoad}
                  onUnmount={onReportZipMapUnmount}
                />
              </div>
              <div className="chart">
                <Chart
                  chartType="PieChart"
                  data={reportZipChartData}
                  width={Math.max(reportContentWidth, reportExportWidth) * 0.5}
                  height={reportExportHeight}
                  options={{
                    chartArea: {
                      width: '100%',
                      height: '100%',
                      right: 0,
                    },
                    fontName: 'Montserrat',
                    fontSize: 12,
                    is3D: true,
                    backgroundColor: { fill: 'transparent' },
                    legend: {
                      position: 'right',
                      alignment: 'center',
                    },
                    tooltip: {
                      ignoreBounds: true,
                      textStyle: {
                        fontSize: 11,
                      },
                    },
                  }}
                  chartEvents={[
                    {
                      eventName: 'ready',
                      callback: (c) => {
                        const chart = c.chartWrapper.getChart();
                        google.visualization.events.removeAllListeners(chart);
                        google.visualization.events.addListener(chart, 'onmouseover', (entry) => {
                          const [ zip, count ] = reportZipChartData[entry.row + 1];
                          setReportZipMapMarkerHover({ zip, count });
                        });
                        google.visualization.events.addListener(chart, 'onmouseout', () => {
                          setReportZipMapMarkerHover({ hover: false });
                        });
                        setReportZipChartImage(chart.getImageURI());
                      },
                    },
                  ]}
                />
              </div>
            </div>}

           <div className="map-container">
             {!reportPhotosActive.length ? <Alert variant="secondary" className="empty">No data</Alert> :
              !isMapLoaded ? <Spinner /> :
              <div className="map" ref={reportMapRef} style={isReportExporting ? { width: reportExportWidth, height: 'auto' } : {}}>
                <GoogleMap
                  mapContainerStyle={{
                    width: '100%',
                    height: reportExportHeight,
                  }}
                  options={{
                    disableDefaultUI: isReportExporting,
                    controlSize: 30,
                    cameraControl: false,
                    zoomControl: true,
                    gestureHandling: 'greedy',
                    styles: [
                      {
                        featureType: 'poi',
                        stylers: [
                          {
                            visibility: 'off',
                          },
                        ],
                      },
                    ],
                  }}
                  onLoad={onMapLoad}
                  onUnmount={onMapUnmount}
                  // onTilesLoaded={() => setIsReportMapTileLoading(true)}
                />
              </div>}
           </div>
        </div>}
      {isReportShowing &&
      <>
        <Modal
          showing={isReportShowing}
          onHide={() => {
            setIsReportShowing(false);
          }}
          fullScreen
          className={classNames('campaign report-select', { client: isClient })}
          body={
            <>
              {reportJobs.length > 1 &&
                <Dropdown className="job">
                  <Dropdown.Toggle variant="outline-secondary">{`${reportJobActive?.item.name}${reportJobActive?.campaignMarket ? ` / ${reportJobActive.campaignMarket.market.name}` : ''}`}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    {reportJobs?.map((job, i) => (
                      <React.Fragment key={i}>
                        <Dropdown.Item
                          className={classNames({ active: job.id === reportJobActive?.id })}
                          onClick={() => {
                            setReportJobActive(job);
                            setReportPhotos(null);
                          }}
                        >
                          {`${job.item.name}${job.campaignMarket ? ` / ${job.campaignMarket.market.name}` : ''}`}
                        </Dropdown.Item>
                      </React.Fragment>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>}
              <Row>
                <Col
                  className="col"
                  md={!reportPhotos?.length ? 12 : 3}
                  style={{ maxHeight: window.innerHeight - 100 }}
                  ref={reportPhotosRef}
                >
                  {!reportPhotos ? <Spinner /> :
                   !reportPhotos.length ? <Alert variant="secondary" className="empty">No photos</Alert> :
                   <WindowScroller scrollElement={reportPhotosRef.current}>
                      {({ isScrolling, registerChild, onChildScroll, scrollTop }) => {
                        const height = reportPhotos
                          .map((_, index) => getReportRowHeight({ index }))
                          .reduce((acc, h) => acc + h, 0);
                        return (
                          <AutoSizer disableHeight>
                            {({ width }) => (
                              <div
                                ref={registerChild}
                                style={{
                                  width: '100%',
                                  height: `${height}px`,
                                }}
                              >
                                <List
                                  style={{
                                    height: '100%',
                                  }}
                                  width={width - 5}
                                  height={height}
                                  autoHeight
                                  overscanRowCount={10}
                                  scrollTop={scrollTop}
                                  isScrolling={isScrolling}
                                  onScroll={onChildScroll}
                                  rowCount={reportPhotos.length}
                                  rowHeight={getReportRowHeight}
                                  rowRenderer={({ index, key, style }) => {
                                    const { id, venuePhotoIndex, venue, date, time } = reportPhotos[index];
                                    const photoData = reportPhotoData[id];
                                    const isSelected = reportPhotosActive.some(({ id }) => photoData?.id === id);
                                    return (
                                      <div
                                        className={classNames('photo', { loading: !photoData })}
                                        key={key}
                                        style={style}
                                      >
                                        {!venuePhotoIndex &&
                                          <div className="venue">
                                            {venue.name}
                                            <div>{`${venue.neighborhood?.name ? `${venue.neighborhood.name}, ` : ''}${venue.market?.name}`}</div>
                                          </div>}
                                        <div>
                                          {!photoData ?
                                            <>
                                              <FontAwesomeIcon icon={faImage} />
                                              <Spinner />
                                            </> :
                                            <div className={classNames({ selected: isSelected })}>
                                              <i
                                                className="select"
                                                onClick={async () => {
                                                  if (isClient) {
                                                    return;
                                                  }

                                                  if (!isSelected) {
                                                    const { data: photoSaved } = await axios.post(CampaignReportPhoto.apiPath, { campaignId: detailData.id, jobId: reportJobActive.id, photoId: photoData.id });
                                                    photoData.crop = photoSaved;
                                                  } else {
                                                    axios.delete(CampaignReportPhoto.apiPath, { data: { campaignId: detailData.id, jobId: reportJobActive.id, photoId: photoData.id } });
                                                  }

                                                  setReportPhotosActive(!isSelected ? [
                                                    ...reportPhotosActive,
                                                    photoData,
                                                  ] : [
                                                    ...reportPhotosActive.filter(({ id }) => photoData.id !== id),
                                                  ]);
                                                }}
                                              />
                                              <img // eslint-disable-line
                                                src={`data:image/jpg;base64,${photoData.photo}`}
                                                onClick={() => setReportPhotoModal({
                                                  ...photoData,
                                                  ...reportPhotosActive.find(({ id }) => photoData.id === id),
                                                  selected: isSelected,
                                                })}
                                              />
                                              {date ? <div className="date">{`${moment(date).format('M/D/YYYY')} ${time ? moment(time, 'HH:mm:ss').format('h:mm a') : ''}`}</div> : null}
                                            </div>}
                                        </div>
                                      </div>
                                    );
                                  }}
                                  onRowsRendered={({ startIndex, stopIndex }) => {
                                    debounceReportRowsRendered({ startIndex, stopIndex });
                                  }}
                                />
                              </div>
                            )}
                          </AutoSizer>
                        );
                      }}
                   </WindowScroller>}
                </Col>
                {reportPhotos?.length ?
                  <Col className="col" md={9}>
                    <div ref={reportPhotosActiveRef}>
                      <Header
                        label={`${detailData?.title} Report`}
                        icons={[
                          exportIcon,
                        ].filter(Boolean)}
                      />
                      {!reportPhotosActive.length ? <Alert variant="secondary" className="empty">None selected</Alert> :
                        <div className="photos-container" style={{ maxHeight: window.innerHeight - 160 }}>
                          <SortableList
                            onSortEnd={(oldIndex, newIndex) => {
                              const sorted = arrayMove(reportPhotosActive, oldIndex, newIndex);
                              setReportPhotosActive(sorted);
                              axios.post(CampaignReportPhoto.apiPath, { campaignId: detailData.id, jobId: reportJobActive.id, reposition: sorted.map(({ id }, i) => ({ fk_photoId: id, position: i })) });
                            }}
                            className="photos"
                            draggedItemClassName="campaign report-select photo drag"
                          >
                            {reportPhotosActive.map((photoData, i) =>
                              <SortableItem key={photoData.id}>
                                <div className={classNames('photo', { loading: !photoData.photo })}>
                                  <div className="selected">
                                    {!photoData.photo ?
                                      <>
                                        <FontAwesomeIcon icon={faImage} />
                                        <FontAwesomeIcon icon={faSquare} />
                                        <Spinner />
                                      </> :
                                      <>
                                        <i
                                          className="select"
                                          onClick={() => {
                                            if (isClient) {
                                              return;
                                            }
                                            setReportPhotosActive([
                                              ...reportPhotosActive.filter(({ id }) => photoData.id !== id),
                                            ]);
                                            axios.delete(CampaignReportPhoto.apiPath, { data: { campaignId: detailData.id, jobId: reportJobActive.id, photoId: photoData.id } });
                                          }}
                                        />
                                        <div
                                          onClick={() =>
                                            setReportPhotoModal({
                                              ...photoData,
                                              selected: true,
                                            })}
                                        >
                                          {photoData.crop && <div className="crop-selection" style={{ top: `${photoData.crop.y}%`, left: `${photoData.crop.x}%`, width: `${photoData.crop.width}%`, height: `${photoData.crop.height}%` }} />}
                                          <img // eslint-disable-line
                                            src={`data:image/jpg;base64,${photoData.photo}`}
                                            draggable={false}
                                            // style={{ transform: `scale(${photoData.scale})` }}
                                            onLoad={(e) => {
                                              if (!photoData.crop) {
                                                photoData.crop = calcReportPhotoCrop(e.currentTarget);
                                                photoData.scale = 1;
                                                changeReportPhoto(photoData);
                                              }
                                            }}
                                            onClick={() =>
                                              setReportPhotoModal({
                                                ...photoData,
                                                selected: true,
                                              })}
                                          />
                                        </div>
                                      </>}
                                  </div>
                                  <div className="caption">
                                    {`${photoData.venue.neighborhood?.name ? `${photoData.venue.neighborhood.name}, ` : ''}${photoData.venue.market?.name}`}
                                  </div>
                                </div>
                              </SortableItem>)}
                          </SortableList>
                        </div>}
                    </div>
                  </Col> : null}
              </Row>
            </>
          }
        />
        <Modal
          showing={!!reportPhotoModal}
          onHide={() => {
            changeReportPhoto(reportPhotoModal);
            setReportPhotoModal(null);
          }}
          fullScreen
          className="campaign report-select image"
          body={
            reportPhotoModal?.selected ?
              <>
                <ReactCrop
                  crop={{ ...reportPhotoModal.crop, unit: '%' }}
                  onChange={(_, percentCrop) => setReportPhotoModal({ ...reportPhotoModal, crop: percentCrop })}
                  keepSelection
                  aspect={16 / 9}
                  disabled={isClient}
                >
                  <img
                    ref={reportPhotoRef}
                    src={`data:image/jpg;base64,${reportPhotoModal.photo}`}
                    // style={{ transform: `scale(${reportPhotoModal.scale})` }}
                  />
                </ReactCrop>
                {/* <div
                  className="controls"
                  style={reportPhotoModal.crop && {
                    top: `${reportPhotoModal.crop.y + reportPhotoModal.crop.height}%`,
                    left: `${reportPhotoModal.crop.x + reportPhotoModal.crop.width}%`,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMagnifyingGlassPlus}
                    onClick={() => setReportPhotoModal({ ...reportPhotoModal, scale: reportPhotoModal.scale + 0.1 })}
                  />
                  <FontAwesomeIcon
                    icon={faMagnifyingGlassMinus}
                    onClick={() => setReportPhotoModal({ ...reportPhotoModal, scale: Math.max(reportPhotoModal.scale - 0.1, 1) })}
                    className={classNames({ disabled: reportPhotoModal.scale === 1 })}
                  />
                </div> */}
              </> :
              <img src={`data:image/jpg;base64,${reportPhotoModal?.photo}`} />
          }
        />
      </>}
    </>
  );
};


const CampaignDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit, nested }) => {
  if (!nested) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  const isClient = _isClient();

  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});
  const [ campaignFormState, setCampaignFormState ] = useState(null);
  // const [ reportDetailData, setReportDetailData ] = useState(null);
  // const [ isReportShowing, setIsReportShowing ] = useState(false);



  const schemaCampaignContact = (type) => {
    const typeKey = (key) => type === 'billing' ? `${type}${capitalize(key)}` : key;
    const fields = cloneDeep(pick(schema, [
      ...[ `fk_${type}ContactId`, 'id' ],
      ...[ 'email', 'phone', 'mobile', 'fax', 'address', 'city', 'state', 'zip' ].map((key) => typeKey(key)),
    ]));

    return Object.keys(fields).reduce((acc, key, i) => ({
      ...acc,
      [key]: {
        ...fields[key],
        form: {
          ...fields[key].form,
          hide: !!fields[key].primaryKey,
          ...i === 0 ? {
            onChange: async ({ item, setFieldValue }) => {
              if (item) {
                const { data: contact } = await axios.get(`${Contact.apiPath}/${item.value}`);
                [ 'email', 'phone', 'mobile', 'fax' ].forEach((key) => setFieldValue(typeKey(key), contact[key]));
              }
            },
          } : {
            readOnly: true,
          },
        },
      },
    }), {});
  };

  const exportVenues = async ({ zip, job } = {}) => {
    const workbook = new ExcelJS.Workbook();
    const reportLogoBase64 = await imageToBase64(reportLogo);
    const escapeRegex = /[\\\/\?\*\[\]\:]/g; // eslint-disable-line
    const jobs = job ? [job] : (await axios.get(`${Job.apiPath}/?campaignId=${data.id}`)).data.items;
    (await Promise.all(jobs.map(async (job) => {
      const { data: { items: venues } } = await axios.get(`${venueApiPath}/?campaignId=${data.id}&jobId=${job.id}&distributionVenue=true`);
      return {
        job,
        venues,
      };
    }))).forEach(({ job, venues }) => {
      const worksheet = workbook.addWorksheet(
        truncate(`${job.campaignMarket.market.name} | ${job.item.name.replace(escapeRegex, ' ')}`, { length: 30 }),
        // { views: [ { showGridLines: false } ] },
      );
      try {
        const weeksOnDisplay = schema.weeksOnDisplay.form.valueGetter(data);
        const periodsOnDisplay = schema.periodsOnDisplay.form.valueGetter(data);

        if (venues.length) {
          const venueRows = venues.map((venue) => ({
            ID: venue.id,
            'Geopath ID': venue.geopathId,
            Name: venue.name,
            ...!isClient && { Email: venue.contactEmail },
            Address: venue.address,
            Zip: venue.zip,
            Type: venue.type?.type,
            Neighborhood: venue.neighborhood?.name,
            County: venue.county,
            Market: venue.market?.name,
            Latitude: venue.latitude,
            Longitude: venue.longitude,
            Facing: venue.facingDirection,
            ...!isClient && {
              'Geopath ID': venue.geopathId,
              'Install Date': venue.installDate,
              'Up Current': venue.upCurrent ? 'Yes' : 'No',
              ...data.routeSheetAudit && { 'Audit Status': venue.auditStatus },
            },
          }));
          worksheet.columns = Object.keys(venueRows[0]).map((key) => ({
            header: key.toUpperCase(),
            key,
          }));
          worksheet.getRow(1).eachCell((cell, colNumber) => {
            cell.style = {
              font: {
                bold: true,
                color: { argb: 'ffffff' },
              },
              fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '0d395f' },
              },
            };
          });
          worksheet.addRows(venueRows);
        }

        worksheet.addImage(
          workbook.addImage({
            base64: reportLogoBase64,
            extension: 'jpg',
          }),
          {
            tl: { col: 0.25, row: 0.25 },
            ext: { width: 150, height: 100 },
          },
        );

        const logoRowCount = 6;
        worksheet.insertRows(1, [
          ...Array.from(Array(logoRowCount), () => []),
          [moment().format('MMM DD, YYYY')],
          [data.client.name],
          [data.title],
          [],
          [],
          [ 'Market Covered:', job.campaignMarket.market.name ],
          job.distributionGoal && [ 'Displays Contracted:', `${job.distributionGoal.toLocaleString()} ${data.type}s` ],
          job.venuesAlltime && [ 'Displays Installed:', `${job.venuesAlltime.toLocaleString()} ${data.type}s` ],
          [ 'Duration:', `${periodsOnDisplay} Period${periodsOnDisplay > 1 ? 's' : ''} / ${weeksOnDisplay} Week${weeksOnDisplay > 1 ? 's' : ''}` ],
          [ 'Start Date:', moment(data.dateStart).format('M/D/YYYY') ],
          data.dateFullInstall && [ 'Full Install Date:', moment(data.dateFullInstall).format('M/D/YYYY') ],
          data.impressionsContracted && [ 'Campaign Impressions Contracted:', data.impressionsContracted.toLocaleString() ],
          data.impressionsTotal && [ 'Campaign Impressions Total (est.)*:', data.impressionsTotal.toLocaleString() ],
          data.impressionsTotal && data.invoiceTotal && [ 'CPM:', calcCpm(data) ],
          [],
          [],
        ].filter(Boolean));

        worksheet.getCell(`A${logoRowCount + 3}`).style = {
          font: {
            bold: true,
          },
        };
        worksheet.columns.forEach((column) => {
          let colLength = 0;
          column.eachCell({ includeEmpty: true }, (cell) => {
            colLength = Math.max(
              colLength,
              20,
              cell.value ? cell.value.toString().length : 0,
            );
          });
          column.width = colLength + 2;
        });

        worksheet.addRows([
          [],
          [],
          ['*Impression data is based on Geopath’s reach & frequency metrics for the WNDW network.'],
          ['When WNDW locations are new and not yet mapped by Geopath, comparable audited media impression data is used.'],
        ]);

        // default styles
        worksheet.eachRow((row) => {
          row.eachCell((cell) => {
            // if (!cell.font?.size) {
            //   cell.font = Object.assign(cell.font || {}, { size: 10 });
            // }
            if (!cell.font?.name) {
              cell.font = Object.assign(cell.font || {}, { name: 'Montserrat' });
            }
          });
        });

      } catch (e) {
        console.log(e);
      }
    });

    const fileName = `${data.title.replace(escapeRegex, ' ')} - Install Report - ${moment().format('MMDDYYYY')}.xlsx`;
    const buffer = await workbook.xlsx.writeBuffer();
    if (zip) {
      await zip.file(
        fileName,
        new Blob([new Uint8Array(buffer)], { type: 'application/octet-stream' }),
      );
    } else {
        saveAs(
          new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' }),
          fileName,
        );
    }
  };

  return (
    <div key={`detail${data?.id}`} className={classNames('detail campaign', { client: isClient })}>
      <Form
        apiPath={apiPath}
        schema={modelParent === Client.model ? alterSchema(schema, { formFieldsHide: ['fk_clientId'] }) :
                isClient ? alterSchema(schema, { formFieldsInfo: [ 'title', 'client.name', 'type', 'markets', 'dateStart', 'dateEnd', 'dateFullInstall', 'dateFinalEnd', 'weeksOnDisplay', 'periodsOnDisplay', 'distributionGoal', 'venuesAlltime', 'impressionsContracted', 'impressionsTotal', 'cpm' ], labels: { distributionGoal: 'Displays Contracted', venuesAlltime: 'Displays Installed', impressionsContracted: 'Impressions Contracted', impressionsTotal: 'Impressions Total' }, formFieldsHide: [ 'routeSheetTotal', 'routeSheetActive', 'distributionGoal', 'venuesAlltime', 'upCurrent', 'dateInitialRoute', 'venuesAudit', 'createdBy', 'dateCreation', 'modifiedBy', 'dateMod' ], formFieldsInfoOnly: true }) : schema}
        data={data}
        readOnly={isClient}
        dataDefault={!data && (
          modelParent === Client.model ? {
            fk_clientId: dataParent.id,
          } :
          modelParent === Contact.model ? {
            fk_primaryContactId: dataParent.id,
            email: dataParent.email,
            phone: dataParent.phone,
            mobile: dataParent.mobile,
            fax: dataParent.fax,
            /* address: dataParent.address,
            city: dataParent.city,
            state: dataParent.state,
            zip: dataParent.zip, */
            fk_clientId: dataParent.fk_clientId,
            } :
          null
        )}
        onSubmit={onSubmit}
        getState={(state) => setCampaignFormState(state)}
      />
      {data && <Tabs
        tabs={[
          {
            key: 'contact',
            title: 'Contacts',
            content:
              <div>
                <Row>
                  <Col>
                    <Form
                      apiPath={apiPath}
                      schema={schemaCampaignContact('primary')}
                      data={data}
                    />
                  </Col>
                  <Col>
                    <Form
                      apiPath={apiPath}
                      schema={schemaCampaignContact('billing')}
                      data={data}
                    />
                  </Col>
                </Row>
              </div>,
          },
          {
            key: 'item',
            title: 'Items',
            content:
              <Crud
                apiPath={CampaignItem.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(CampaignItem.schema, { gridCols: [ 'item.name', 'distributionGoal', 'venuesAlltime', 'upCurrent', 'distributionAudit', 'dateStart', 'dateInitialPosting', 'dateEnd' ] })}
                schemaParent={schema}
                routePath={CampaignItem.routePath}
                label={CampaignItem.label}
                nested
                dataParent={data}
                model={CampaignItem.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'market',
            title: 'Markets',
            content:
              <Crud
                apiPath={CampaignMarket.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(CampaignMarket.schema, { gridCols: [ 'market.name', 'neighborhoods' ] })}
                schemaParent={schema}
                routePath={CampaignMarket.routePath}
                label={CampaignMarket.label}
                nested
                showDelete
                dataParent={data}
                model={CampaignMarket.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'job',
            title: 'Jobs',
            content:
              <Crud
                apiPath={Job.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(Job.schema, { gridCols: [ 'id', 'item.name', 'campaignMarket.market.name', 'distributionGoal', 'venuesAlltime', 'upCurrent', 'distributionAudit', 'dateStart' ] })}
                schemaParent={schema}
                routePath={Job.routePath}
                label={Job.label}
                nested
                dataParent={data}
                model={Job.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'sheet',
            title: 'Sheets',
            content:
              <Crud
                apiPath={RouteSheet.apiPath}
                apiParams={{ distributionRoute: true, campaignId: id }}
                schema={alterSchema(RouteSheet.schema, { gridCols: [ 'id', 'date', 'fk_routeId', 'route.neighborhood.name', 'rep.fullName', 'photosProcessedBy', 'status', 'type', 'distributionAudit' ] })}
                schemaParent={schema}
                routePath={RouteSheet.routePath}
                label={RouteSheet.label}
                nested
                dataParent={data}
                model={RouteSheet.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'activity',
            title: 'Activities',
            content:
              <Crud
                apiPath={Activity.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(Activity.schema, { gridColsOrder: Activity.nestedGridCols })}
                schemaParent={schema}
                routePath={Activity.routePath}
                label={Activity.label}
                nested
                dataParent={data}
                model={Activity.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'plan',
            title: 'Plan',
            content:
              <Tabs
                tabs={[
                  {
                    key: 'venues',
                    title: 'Venues',
                    content:
                      <Venue
                        dataParent={data}
                        modelParent={model}
                      />,
                  },
                  {
                    key: 'rates',
                    title: 'Rates',
                    content:
                      <PlanGoogleDocs
                        data={data}
                        docType={GoogleDoc.type.RATE}
                      />,
                  },
                  {
                    key: 'deck',
                    title: 'Deck',
                    content:
                      <PlanGoogleDocs
                        data={data}
                        docType={GoogleDoc.type.DECK}
                      />,
                  },
                ]}
              />,
          },
          {
            key: 'agreement',
            title: 'Agreement',
            content:
              <Agreement
                data={data}
                schema={schema}
                apiPath={apiPath}
                campaignFormState={campaignFormState}
              />,
          },
          {
            key: 'photo',
            title: 'Photos',
            content:
              <Photo
                dataParent={data}
                modelParent={model}
              />,
          },
          {
            key: 'venue',
            title: 'Venues',
            content:
              <Venue
                dataParent={data}
                modelParent={model}
                apiParams={{ distributionVenue: true }}
                showAdd={false}
                showMap={false}
                exportData={exportVenues}
              />,
          },
          {
            key: 'attachments',
            title: 'Attachments',
            content:
              <Attachment
                schemaParent={schema}
                modelParent={model}
                dataParent={data}
              />,
          },
          {
            key: 'flow',
            title: 'Flow',
            content:
              <Crud
                apiPath={CampaignStage.apiPath}
                apiParams={{ campaignId: id }}
                schema={alterSchema(CampaignStage.schema, {
                    gridColsShow: [ 'flow.notes', 'flow.completedBy.fullName', 'flow.completedDate' ],
                    gridColsHide: [ 'status', 'number', 'position' ],
                    formFieldsShow: ['flow.notes'],
                    formFieldsHide: [ 'status', 'number', 'position' ],
                    formFieldsInfo: [ 'stage', 'team' ],
                  })}
                schemaParent={schema}
                routePath={CampaignStage.routePath}
                label={CampaignStage.label}
                nested
                dataParent={data}
                model={CampaignStage.model}
                modelParent={model}
                checkSelect
                showAdd={false}
                checkSelectedField="flow.completedDate"
                onCheckSelected={(node) => CampaignStageFlow.onSelected({ node, dataParent: data })}
                // exportData: (rowData) => rowData?.filter((item) => item.campaignPlanVenue),
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'report',
            title: 'Report',
            clientAccess: true,
            content:
              <Report
                key={`report${data.id}`}
                detailData={data}
                exportVenues={exportVenues}
              />,
          },
        ].filter((tab) => !isClient || tab.clientAccess)}
      />}
      {isClient && (data.logo || data.client?.logo) &&
      <div className="logo">
        <img
          src={`data:image/jpg;base64,${data.logo || data.client?.logo}`}
        />
      </div>}
    </div>
  );
};

export default CampaignDetail;
