import React from 'react';
import axios from 'axios';
import jwt from 'jwt-decode';
import { camelCase, startCase } from 'lodash';

const localStorageKey = 'user';
let sessionInterval = null;

export const setAuthHeader = (token) => {
  axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : undefined;
};

export const clearAuthHeader = () =>
  setAuthHeader();

export const getAuth = () =>
  JSON.parse(localStorage.getItem(localStorageKey)) || {};

export const getToken = () =>
  getAuth().token;

export const role = {
  ACCOUNT_ASSISTANT: '',
  ACCOUNT_EXECUTIVE: '',
  BA: 'BA',
  CLIENT: '',
  EXECUTIVE_DIRECTOR: '',
  ROUTE_MANAGER: '',
  SALES: '',
};
Object.keys(role).map((key) => {
  if (!role[key]) {
    role[key] = startCase(camelCase(key));
  }
  return role[key];
});

export const getUserId = () =>
  getAuth()?.user?.id;

export const getUserRole = () =>
  getAuth()?.user?.role;

export const getCampaignId = () =>
  jwt(getToken())?.campaignId;

export const isBA = () =>
  getAuth()?.user?.role === role.BA;

export const isClient = () =>
  getAuth()?.user?.role === role.CLIENT;

export const isAuthenticated = (token = getToken()) => {
  return !!token && Date.now() < jwt(token).exp * 1000;
};

export const logout = () => {
  localStorage.removeItem(localStorageKey);
  clearAuthHeader();
  clearInterval(sessionInterval);
  window.location.href = '/#/login';
};

const initSession = (token) => {
  setAuthHeader(token);
  sessionInterval = setInterval(() => {
    if (!isAuthenticated()) {
      logout();
      window.location.href = '/#/login';
    }
  }, 5000);
};

export const login = ({ email, password, campaignAuthKey }) =>
  axios
    .post('/users/auth', {
      email,
      password,
      campaignAuthKey,
    })
    .then(({ data }) => {
      localStorage.setItem(localStorageKey, JSON.stringify(data));
      initSession(data.token);
      return Promise.resolve(data);
    })
    .catch(({ response }) =>
      Promise.reject(response));

export const refresh = () => {
  if (isAuthenticated()) {
    initSession(getToken());
    return axios
      .get('/users/auth')
      .then(({ data }) => {
        return Promise.resolve(data);
      })
      .catch(({ response }) => {
        logout();
        return Promise.reject(response);
      });
  }
  return Promise.reject();
};
