import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { chunk, orderBy, debounce, range, truncate } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { isBA as _isBA, isClient as _isClient, getCampaignId } from 'services/auth';
import Crud from 'components/crud';
import Spinner from 'components/spinner';
import Modal from 'components/modal';
import Header from 'components/header';
import Pipeline from 'components/pipeline';
import iconType from 'utils/iconType';
import { alterSchema } from 'utils/schema';
import { paths, isDetailPath } from 'services/route';
import { apiPath, schema, model, label, routePath, status, stage, stageInProgress } from './config';


const Campaign = ({ isAuthenticated }) => {
  const isClient = _isClient();
  const [ isProposedShowing, setIsProposedShowing ] = useState(false);
  const [ isPipelineShowing, setIsPipelineShowing ] = useState(false);
  const [ isAllShowing, setIsAllShowing ] = useState(false);
  const [ reportSelectDetailData, setReportSelectDetailData ] = useState(null);
  const [ isReportSelectShowing, setIsReportSelectShowing ] = useState(false);
  const [ isTagCopied, setIsTagCopied ] = useState(false);
  const [ isClientLinkCopied, setIsClientLinkCopied ] = useState(false);
  const [ apiUrl, setApiUrl ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);

  const navigate = useNavigate();
  const params = useParams();
  if (isClient && !isDetailPath({ params })) {
    navigate(`/${paths.campaigns.campaigns}/${getCampaignId()}`);
  }

  useEffect(() => {
    if (isLoading && isAuthenticated) {
        setIsLoading(false);
    }
  }, [ isLoading, isAuthenticated ]);

  return (
    isLoading ? <Spinner /> :
    <div className={classNames('index campaign', { 'pipeline-showing': isPipelineShowing })}>
      <Crud
        apiPath={apiPath}
        apiParams={{ index: true, proposed: isProposedShowing, all: isAllShowing }}
        schema={
          isProposedShowing ? alterSchema(schema, { gridColsHide: [ 'distributionGoal', 'venuesAlltime', 'upCurrent', 'venuesAudit', 'status' ], gridColsShow: ['type'] }) :
          alterSchema(schema, { gridColsHide: isAllShowing ? [ 'hot', 'avgBudget', 'dateLastActivity' ] : [ 'hot', 'avgBudget', 'dateLastActivity', 'dateCreation' ] })
        }
        model={model}
        label={label}
        routePath={routePath}
        showAdd={!isClient}
        icons={[
          ...!isClient ? [
            {
              type: iconType.PROPOSED,
              onClick: () => {
                setIsProposedShowing(!isProposedShowing);
                setIsAllShowing(false);
              },
              active: isProposedShowing,
              tooltip: 'Toggle Proposed',
            },
            {
              type: iconType.ALL,
              onClick: () => {
                setIsAllShowing(!isAllShowing);
                setIsProposedShowing(false);
              },
              active: isAllShowing,
              tooltip: 'Toggle All',
            },
            {
              type: iconType.PIPELINE,
              onClick: () => setIsPipelineShowing(!isPipelineShowing),
              active: isPipelineShowing,
              tooltip: 'Toggle Pipeline',
            },
            {
              type: iconType.TAG,
              detail: true,
              tooltip:
                isTagCopied ?
                  <div>
                    Copied Campaign Tag
                    <i>paste into email body</i>
                  </div> :
                  <div>
                    Copy Campaign Tag
                    <i>paste into email body</i>
                  </div>,
              onClick: async ({ detailData }) => {
                setIsTagCopied(true);
                setTimeout(() => {
                  setIsTagCopied(false);
                }, 2000);
                window.navigator.clipboard.writeText(`#campaign${detailData.id}`);
              },
            },
            {
              type: iconType.URL,
              detail: true,
              tooltip:
                isClientLinkCopied ?
                  <div>
                    Copied Client Link
                  </div> :
                  <div>
                    Copy Client Link
                  </div>,
              onClick: async ({ detailData }) => {
                setIsClientLinkCopied(true);
                setTimeout(() => {
                  setIsClientLinkCopied(false);
                }, 2000);
                window.navigator.clipboard.writeText(`${document.location.href}?auth=${detailData.authKey}`);
              },
            },
          ] : [],
        ].filter(Boolean)}
        getApiUrl={setApiUrl}
      />
      {isPipelineShowing && <Pipeline
        stage={isProposedShowing ? stage : stageInProgress}
        stageField="stage"
        apiPath={apiPath}
        apiUrl={apiUrl}
        filterMatch={({ item, query }) =>
          item.title?.toLowerCase().includes(query) ||
          item.client?.name?.toLowerCase().includes(query) ||
          item.markets?.some(({ name, code }) => name.toLowerCase().includes(query) || code.toLowerCase().includes(query))}
        renderCard={(item) => ({
          title:
          <>
            <div className="title">{item.title}</div>
            {item.hot && <div className="hot">{item.hot}</div>}
          </>,
          description:
            <div className="description">
              <div>{item.client.name}</div>
              <div>{moment(item.dateStart).format('M/D/YYYY')}{item.dateEnd && <> &ndash; {moment(item.dateEnd).format('M/D/YYYY')}</>}</div>
              {item.type ? <div>{item.type}</div> : ''}
              {item.markets?.length ? <div>{item.markets.map(({ code }) => code).join(', ')}</div> : ''}
              {isProposedShowing && item.avgBudget ? <div>{`$${item.avgBudget.toLocaleString()}`}</div> : item.stage === stageInProgress.PAYMENT && item.invoiceTotal ? <div>{`$${(item.invoicePaid || 0).toLocaleString()} of $${item.invoiceTotal.toLocaleString()} paid`}</div> : ''}
              {isProposedShowing && item.dateLastActivity ? <div><span>Last Contact:</span>{moment(item.dateLastActivity).format('M/D/YYYY')}</div> : ''}
              {isProposedShowing && item.stage === stage.RFP_REQUEST && item.due ? <div><strong><span>DUE:</span>{item.due}</strong></div> : ''}
              {isProposedShowing && item.stage === stage.ON_HOLD && item.dateHoldExpires ? <div><span>Hold Expires:</span>{moment(item.dateHoldExpires).format('M/D/YYYY')}</div> : ''}
            </div>,
          // draggable: isProposedShowing,
        })}
      />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps)(Campaign);
