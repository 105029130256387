export default {
  ADD: {
    className: 'plus',
  },
  EXPORT: {
    className: 'download',
  },
  IMPORT: {
    className: 'upload',
  },
  DELETE: {
    className: 'trash-can',
  },
  BACK: {
    className: 'arrow-left',
  },
  FORWARD: {
    className: 'arrow-right',
  },
  PIPELINE: {
    className: 'table-columns',
  },
  STREET_VIEW: {
    className: 'street-view',
  },
  MAP: {
    className: 'location-dot',
  },
  LINK_EXTERNAL: {
    className: 'up-right-from-square',
  },
  LINK: {
    className: 'arrow-up-right-from-square linked',
  },
  PEN: {
    className: 'pen-clip',
  },
  UP: {
    className: 'arrow-up',
  },
  ACTIVATE: {
    className: 'check',
  },
  ACTIVE: {
    className: 'circle-check',
    regular: true,
  },
  COMPLETE: {
    className: 'circle-check',
  },
  FULL_SCREEN: {
    key: 'fullscreen',
    className: 'up-right-and-down-left-from-center',
  },
  PROPOSED: {
    className: 'lightbulb',
    regular: true,
  },
  ALL: {
    className: 'layer-group',
  },
  REPORT: {
    className: 'file-lines',
    regular: true,
  },
  TAG: {
    className: 'hashtag',
    regular: true,
  },
  EXPAND: {
    className: 'angle-right',
  },
  COLLAPSE: {
    className: 'angle-down',
  },
  IMAGE: {
    className: 'image',
  },
  SEARCH: {
    className: 'search',
  },
  EMAIL: {
    className: 'envelope',
  },
  COPY: {
    className: 'clone',
  },
  GEAR: {
    className: 'cog',
  },
  URL: {
    className: 'link',
  },
};
