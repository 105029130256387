import React from 'react';
import { matchPath } from 'react-router-dom';
import Home from 'views/home';
import Login from 'views/login';
import CRM from 'views/crm';
import Client from 'views/client';
import ClientDetail from 'views/client/detail';
import Contact from 'views/contact';
import ContactDetail from 'views/contact/detail';
import Vendor from 'views/vendor';
import VendorDetail from 'views/vendor/detail';
import Venue from 'views/venue';
import VenueDetail from 'views/venue/detail';
import Campaign from 'views/campaign';
import CampaignDetail from 'views/campaign/detail';
import CampaignItem from 'views/campaignItem';
import CampaignItemDetail from 'views/campaignItem/detail';
import CampaignMarket from 'views/campaignMarket';
import CampaignMarketDetail from 'views/campaignMarket/detail';
import Job from 'views/job';
import JobDetail from 'views/job/detail';
import Item from 'views/item';
import ItemDetail from 'views/item/detail';
import ManualCount from 'views/manualCount';
import ManualCountDetail from 'views/manualCount/detail';
import Movement from 'views/movement';
import MovementDetail from 'views/movement/detail';
import Activity from 'views/activity';
import ActivityDetail from 'views/activity/detail';
import Route from 'views/route';
import RouteDetail from 'views/route/detail';
import RouteSheet from 'views/routeSheet';
import RouteSheetDetail from 'views/routeSheet/detail';
import RouteDistribution from 'views/routeDistribution';
import User from 'views/user';
import UserDetail from 'views/user/detail';
import Street from 'views/street';
import StreetDetail from 'views/street/detail';
import Admin from 'views/admin';
import Setting from 'views/setting';
import Value from 'views/value';
import Market from 'views/market';
import MarketDetail from 'views/market/detail';
import Neighborhood from 'views/neighborhood';
import NeighborhoodDetail from 'views/neighborhood/detail';
import ClientType from 'views/clientType';
import ClientTypeDetail from 'views/clientType/detail';
import VendorType from 'views/vendorType';
import VendorTypeDetail from 'views/vendorType/detail';
import VenueType from 'views/venueType';
import VenueTypeDetail from 'views/venueType/detail';
import ActivityType from 'views/activityType';
import ActivityTypeDetail from 'views/activityType/detail';
import Profile from 'views/profile';
import BookedBusiness from 'views/bookedBusiness';
import Payroll from 'views/payroll';
import CampaignStage from 'views/campaignStage';
import CampaignStageDetail from 'views/campaignStage/detail';
import { role } from 'services/auth';

const rolesExcludeBA = Object.keys(role).filter((key) => key.toLowerCase() !== role.BA.toLowerCase()).map((key) => role[key]);
const rolesExcludeClient = Object.keys(role).filter((key) => key.toLowerCase() !== role.CLIENT.toLowerCase()).map((key) => role[key]);
const rolesExcludeBAClient = Object.keys(role).filter((key) => ![ role.BA.toLowerCase(), role.CLIENT.toLowerCase() ].includes(key.toLowerCase())).map((key) => role[key]);

export const routes = [
  { path: '', key: 'home', Component: Home },
  { path: 'login', Component: Login },
  {
    key: 'crm',
    label: 'CRM',
    path: 'crm',
    Component: CRM,
    index: true,
    roles: rolesExcludeBAClient,
    children: [
      { label: 'Companies', path: 'companies', Component: Client, DetailComponent: ClientDetail },
      { label: 'Contacts', path: 'contacts', Component: Contact, DetailComponent: ContactDetail },
      { label: 'Vendors', path: 'vendors', Component: Vendor, DetailComponent: VendorDetail },
    ],
  },
  {
    key: 'campaigns',
    label: 'Campaigns',
    path: 'campaigns',
    roles: rolesExcludeBAClient,
    children: [
      { label: 'Campaigns', path: 'campaigns', Component: Campaign, DetailComponent: CampaignDetail },
      { label: 'Campaign Items', path: 'campaignItems', Component: CampaignItem, DetailComponent: CampaignItemDetail, nested: true, roles: rolesExcludeBAClient },
      { label: 'Campaign Markets', path: 'campaignMarkets', Component: CampaignMarket, DetailComponent: CampaignMarketDetail, nested: true, roles: rolesExcludeBAClient },
      { label: 'Jobs', path: 'jobs', Component: Job, DetailComponent: JobDetail, roles: rolesExcludeBAClient },
      { label: 'Items', path: 'items', Component: Item, DetailComponent: ItemDetail, roles: rolesExcludeBAClient },
      { label: 'Item Manual Counts', path: 'manualCounts', Component: ManualCount, DetailComponent: ManualCountDetail, nested: true, roles: rolesExcludeBAClient },
      { label: 'Item Movements', path: 'movements', Component: Movement, DetailComponent: MovementDetail, nested: true, roles: rolesExcludeBAClient },
      { divider: true, roles: rolesExcludeBAClient },
      { label: 'Booked Business', path: 'bookedBusiness', Component: BookedBusiness, roles: rolesExcludeBAClient },
    ],
  },
  {
    key: 'routes',
    label: 'Routes',
    path: 'routes',
    navPath: 'distribution',
    roles: rolesExcludeClient,
    children: [
      { label: 'Distribution', path: 'distribution', Component: RouteDistribution, roles: rolesExcludeBA },
      { label: 'Sheets', path: 'routeSheets', Component: RouteSheet, DetailComponent: RouteSheetDetail },
      { label: 'Routes', path: 'routes', Component: Route, DetailComponent: RouteDetail, roles: rolesExcludeBA },
      { label: 'Streets', path: 'streets', Component: Street, DetailComponent: StreetDetail, nested: true, roles: rolesExcludeBA },
      { label: 'Venues', path: 'venues', Component: Venue, DetailComponent: VenueDetail, roles: rolesExcludeBA },
    ],
  },
  {
    key: 'activities',
    label: 'Activities',
    path: 'activities',
    roles: rolesExcludeBAClient,
    children: [
      { label: 'Activities', path: 'activities', Component: Activity, DetailComponent: ActivityDetail },
      // { label: 'Today\'s Report', path: 'report' },
      // { label: 'Yesterday\'s Report', path: 'report' },
    ],
  },
  {
    key: 'admin',
    label: 'Admin',
    path: 'admin',
    Component: Admin,
    index: true,
    roles: rolesExcludeBAClient,
    children: [
      { label: 'Settings', path: 'settings', Component: Setting },
      { label: 'Values', path: 'values', Component: Value },
      { label: 'Markets', path: 'markets', Component: Market, DetailComponent: MarketDetail, nested: true },
      { label: 'Neighborhoods', path: 'neighborhoods', Component: Neighborhood, DetailComponent: NeighborhoodDetail, nested: true },
      { label: 'Client Types', path: 'clientTypes', Component: ClientType, DetailComponent: ClientTypeDetail, nested: true },
      { label: 'Vendor Types', path: 'vendorTypes', Component: VendorType, DetailComponent: VendorTypeDetail, nested: true },
      { label: 'Venue Types', path: 'venueTypes', Component: VenueType, DetailComponent: VenueTypeDetail, nested: true },
      { label: 'Activity Types', path: 'activityTypes', Component: ActivityType, DetailComponent: ActivityTypeDetail, nested: true },
      { label: 'Campaign Stages', path: 'campaignStages', Component: CampaignStage, DetailComponent: CampaignStageDetail, nested: true },
      { label: 'Personnel', path: 'users', Component: User, DetailComponent: UserDetail, roles: [role.EXECUTIVE_DIRECTOR] },
      { label: 'Payroll', path: 'payroll', Component: Payroll, roles: [role.EXECUTIVE_DIRECTOR] },
    ],
  },
  {
    key: 'user',
    label: <i className="fa fa-user" />,
    icon: true,
    children: [
      { label: 'Profile', path: 'profile', Component: Profile, roles: rolesExcludeClient },
      { label: 'Logout', onClick: ({ logout }) => logout() },
    ],
  },
];

export const paths = routes.reduce((acc, { key, path, children }) => ({
  ...acc,
  [key || path]: children ?
    children.reduce((accChildren, { key: childKey, path: childPath, index }) => ({
      ...accChildren,
      [index ? 'index' : childKey || childPath]: childPath,
    }), {}) :
    path,
}), { });

export const matchRoute = ({ pathname, children = null }) =>
  (children || [
    ...routes.filter(({ children }) => children).flatMap(({ children }) => children),
    ...routes.filter(({ Component, children }) => Component && children),
  ])
  .find(({ path }) => matchPath(`${path}/*`, `${!pathname?.startsWith('/') ? '/' : ''}${pathname}`));

export const isAddPath = ({ pathname }) =>
  pathname.endsWith('/add');

export const isDetailPath = ({ pathname, params = null }) => {
  if (!params) {
    if (!isAddPath({ pathname })) {
      params = matchPath(`${matchRoute({ pathname }).path}/:id`, pathname)?.params;
    }
  }
  return !!params?.id;
};
