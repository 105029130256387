export const fitBounds = ({ map, bounds, maxZoom }) => {
  map.fitBounds(bounds);
  if (maxZoom) {
    const listener = google.maps.event.addListener(map, 'idle', () => {
      google.maps.event.removeListener(listener);
      if (map.getZoom() > maxZoom) {
        map.setZoom(maxZoom);
      }
    });
  }
};
