import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Button,
  Alert,
  Container,
  Row,
  Col,
  FormCheck,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { MDBInput, MDBTextArea, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit';
import { WithContext as ReactTags, KEYS } from 'react-tag-input';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Downshift from 'downshift';
import Upload from 'rc-upload';
import { AutoSizer, List } from 'react-virtualized';
import Highlighter from 'react-highlight-words';
import { Formik } from 'formik';
import * as Yup from 'yup';
import pluralize from 'pluralize';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPlus } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { orderBy, get, set, chunk, debounce, omit } from 'lodash';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { saveAs } from 'file-saver';
import { getPrimaryKey } from 'utils/schema';
import isEmpty from 'utils/isEmpty';
import iconType from 'utils/iconType';
import { base64ToArrayBuffer } from 'utils/encoding';
import * as Route from 'services/route';
import Spinner from './spinner';
import Modal from './modal';
import Header from './header';
import Crud from './crud';

const Map = ({ latitude, longitude, googlePlaceId }) => {
  const [ _, setMap ] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places'],
  });

  const coordinates = {
    lat: latitude,
    lng: longitude,
  };

  const onMapLoad = useCallback((map) => {
    // const bounds = new window.google.maps.LatLngBounds(value);
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onMapUnmount = useCallback(() => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      center={coordinates}
      zoom={17}
      options={{ controlSize: 22 }}
      onLoad={onMapLoad}
      onUnmount={onMapUnmount}
    >
      <Marker
        onLoad={onMapLoad}
        position={coordinates}
        onClick={() => googlePlaceId && window.open(`https://www.google.com/maps/place/?q=place_id:${googlePlaceId}`, '_blank')}
      />
    </GoogleMap>
  ) : null;
};

export default ({
  apiPath,
  schema,
  data,
  dataDefault,
  dataParent,
  model,
  modelParent,
  isDetail,
  onSubmit,
  submitLabel,
  defaultSubmitData,
  hideSubmit,
  getState,
  readOnly,
  size = 'sm',
}) => {

  const isAdd = !data;
  const isEdit = !!data;
  const primaryKey = getPrimaryKey(schema);

  const [ isLoading, setIsLoading ] = useState(true);
  const [ fields, setFields ] = useState([]);
  const [ rowFields, setRowFields ] = useState([]);
  const [ infoFields, setInfoFields ] = useState([]);
  const [ infoFieldColBreakIndex, setInfoFieldColBreakIndex ] = useState(null);
  const [ fieldFocused, setFieldFocused ] = useState(null);
  const [ validationSchema, setValidationSchema ] = useState(null);
  const [ hasSubmitted, setHasSubmitted ] = useState(false);
  const [ submitError, setSubmitError ] = useState(null);
  const [ submitWarnMsg, setSubmitWarnMsg ] = useState(null);
  const [ isUploading, setIsUploading ] = useState(false);
  const [ inlineAddModal, setInlineAddModal ] = useState(null);
  const [ imageModal, setImageModal ] = useState(null);
  const [ editValues, setEditValues ] = useState({});
  const [ textareaFullScreen, setTextareaFullScreen ] = useState(null);
  const [ nestedDetail, setNestedDetail ] = useState(null);

  const initForm = async () => {
    const _fields = [];
    let _colFields = [[]];
    const _rowFields = [_colFields];
    const _infoFields = [];
    const _validationSchema = {};
    const _promises = [];
    const _promisesUnique = {};
    let hideField = null;
    Object.keys(schema).map((key, i) => {
      let field = { key, ...schema[key] };
      if (field?.form?.info) {
        _infoFields.push(field);
        if (window.innerWidth > 575 && field.form.infoColBreak) {
          setInfoFieldColBreakIndex(_infoFields.length - 1);
        }
        return false;
      }
      if ((isAdd && field?.form?.hideAdd) || (isEdit && field?.form?.hideEdit)) {
        hideField = field;
      }
      if (field?.form?.hide || field === hideField) {
        return false;
      }
      field.form = field.form || {};

      let colBreak = field.form?.colBreak;
      if (hideField?.form.colBreak) {
        hideField = null;
        colBreak = true;
      }

      if (field.form.col === 1) {
        field = [field];
      } else if (field.form.col > 1) {
        const colFields = _colFields[_colFields.length - 1];
        colFields[colFields.length - 1].push(field);
        return false;
      }

      if (field.form?.rowBreak) {
        _colFields = [[]];
        _rowFields.push(_colFields);
      }

      if (colBreak && _colFields[0].length) {
        _colFields.push([field]);
      } else {
        _colFields[_colFields.length - 1].push(field);
      }

      return field;
    }).filter(Boolean)
      .flat()
      .forEach((field) => {
        const { key, required, form } = field;
        let { type } = field;
        if (!type) {
          field.type = type = 'text'; // eslint-disable-line
        }
        if (form.type !== 'tag') {
          if (type === 'text') {
            _validationSchema[key] = Yup.string();
          } else if (type === 'number') {
            _validationSchema[key] = Yup.number();
          } else if (type === 'price') {
            _validationSchema[key] = Yup.number();
            field.type = 'number';
            field.decimals = 2;
          } else if (type === 'percent') {
            _validationSchema[key] = Yup.number().min(0, 'Min 0').max(100, 'Max 100');
            field.type = 'number';
          } else if (type === 'date') {
            _validationSchema[key] = Yup.date();
          } else if (type === 'boolean') {
            _validationSchema[key] = Yup.boolean();
            form.type = 'checkbox';
          } else if (type === 'password') {
            _validationSchema[key] = Yup.string();
          } else if (type === 'email') {
            _validationSchema[key] = Yup.string().email('Invalid Email');
          } else if (type === 'select') {
            _validationSchema[key] = Yup.string().oneOf(form?.items.map((o) => o.value));
          } else if (type === 'array') {
            _validationSchema[key] = Yup.array();
            if (required) {
              _validationSchema[key] = _validationSchema[key].min(1, 'Required');
            }
            field.many = true;
          }
          if (required) {
            _validationSchema[key] = _validationSchema[key].nullable().required('Required');
          } else {
            _validationSchema[key] = _validationSchema[key]?.nullable();
          }
        }

        if (form?.valueGetter || readOnly) {
          form.readOnly = true;
        }
        if (form?.freeForm) {
          form.valueKey = form.labelKey;
        }
        if (form?.itemsUrl) {
          form.initItems = async (data) => {
            let itemsUrl = typeof form.itemsUrl === 'string' ? form.itemsUrl : form.itemsUrl(data, { dataParent, modelParent });
            if (itemsUrl) {
              itemsUrl = `${itemsUrl}${!itemsUrl.includes('?') ? '?' : '&'}fields=${form.valueKey}${form.valueKey !== form.labelKey ? `,${form.labelKey}` : ''}${form.primaryKey ? `,${form.primaryKey}` : ''}${form.raw !== false ? '&raw=true' : ''}`;
              _promisesUnique[itemsUrl] = _promisesUnique[itemsUrl] || axios.get(itemsUrl);
              const { data: { items } } = await _promisesUnique[itemsUrl];
              form.items = orderBy(items.map((item) => ({ value: get(item, form.valueKey), label: get(item, form.labelKey)?.toString(), ...form.primaryKey && { primaryKey: get(item, form.primaryKey) } })).filter((item) => !!item.label), [(item) => item.label?.toString().toLowerCase()], ['asc']);
            } else {
              form.items = [];
            }
            return form.items;
          };
          _promises.push(() => form.initItems(data || dataDefault));
        } else {
          form.initItems = (items) => {
            form.items = items.map((item) => (typeof item === 'string' ? { value: item, label: item } : item));
          };
          if (form?.items?.length) {
            form.initItems(form.items);
          }
        }
        if (form?.init) {
          form.init({ values: data, field });
        }
        if (field.type === 'image' && data?.googleCloudUrl) {
          const url = data.googleCloudUrl;
          delete data.googleCloudUrl;
          _promises.push(() => fetch(url, { cache: 'no-cache' })
              .then((response) => response.blob())
              .then((blob) => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  data[key] = reader.result.split(',')[1];
                  resolve();
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })));
        }

        _fields.push(field);
      });

    await Promise.all(_promises.map(((promise) => promise())));

    setFields(_fields);
    setRowFields(_rowFields);
    setInfoFields(_infoFields);
    setValidationSchema(Yup.object().shape({ ..._validationSchema }));
    setIsLoading(false);
  };

  const getField = (key) => fields.find((field) => field.key === key);

  useEffect(() => {
    initForm();
  }, [ schema, data ]);

  const hideInlineAddModal = () => {
    setInlineAddModal(null);
  };

  return isLoading ? <Spinner /> : (fields.length || infoFields.length) && validationSchema ?
    <div className={classNames('form', size)}>
      <Formik
        enableReinitialize
        initialValues={
          data ? [ ...fields, ...infoFields ].reduce((acc, { key, decimals, form }) => {
            let value = get(data, key);
            value = value !== null && decimals ? parseFloat(value).toFixed(decimals) : value;
            return form.nested ? set(acc, key, value) : { ...acc, [key]: value };
          }, {}) :
          {
            ...fields.filter(({ form }) => form.defaultValue).reduce((acc, { key, form: { defaultValue } }) => {
              return {
                ...acc,
                [key]: defaultValue,
              };
            }, {}),
            ...dataDefault,
          }
        }
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          try {
            setSubmitError(null);
            const response = await onSubmit(values);
            //const response = await (onSubmit ? onSubmit(values) : axios.post(apiPath, values));
            if (response?.error?.msg) {
              setSubmitError(response.error.msg);
            } else {
              // TODO: callback prop here
            }
          } catch (error) {
            if (error?.response?.data?.msg) {
              if (error.response.data.warn) {
                setSubmitError(null);
                setSubmitWarnMsg(error.response.data.msg);
              } else {
                setSubmitWarnMsg(null);
                setSubmitError(error.response.data.msg);
              }
            }
          }
          //resetForm();
          setSubmitting(false);
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setValues,
          } = props;

          if (getState) {
            const getStateDebounce = useCallback(
              debounce((p) => {
                getState(p);
              }, 300),
              [],
            );
            useEffect(() => {
              getStateDebounce(props);
            }, [values]);
          }

          const shouldValidate = ({ key, forceValidate }) =>
            forceValidate || (isEdit ? values[key] !== editValues[key] : hasSubmitted);

          const isValid = ({ key, required, type, forceValidate }) =>
            shouldValidate({ key, forceValidate }) &&
            (
              !errors[key] ||
              (
                required &&
                (
                  type === 'array' ? values[key]?.length : (typeof values[key] !== 'undefined' && values[key] != null && values[key] !== '')
                )
              )
            );

          const isInvalid = (field) =>
            shouldValidate(field) && !isValid(field);

          const submitValue = async (field, value) => {
            if (isEdit) {
              const { key, type, form } = field;
              if (type === 'date') {
                value = value || null;
              } else if (form.type === 'tag') {
                value = value ? value.map(({ text }) => text) : null;
              }
              const formData = { [key]: value };
              const { data: updatedItem } = await (form.handleSubmit ? form.handleSubmit({ formData }) : axios.patch(`${apiPath}${primaryKey ? `/${data[primaryKey]}` : ''}`, { ...formData, ...defaultSubmitData }));
              if (form.mapUpdate) {
                setFieldValue('latitude', updatedItem.latitude);
                setFieldValue('longitude', updatedItem.longitude);
              }
              form.onSubmit?.({ updatedItem, setFieldValue });
            }
          };

          const onBlur = (field, e) => {
            const { key, type, decimals } = field;
            if (isEdit) {
              if (isValid(field)) {
                let value = values[key];
                if ((!type || type === 'string') && value) {
                  value = value.trim();
                } else if (type === 'number') {
                  if (value === '') {
                    value = null;
                    setFieldValue(key, value);
                  } else if (decimals && value != null) {
                    setFieldValue(key, parseFloat(value).toFixed(decimals));
                  }
                }
                submitValue(field, value);
              }
              setEditValues(values);
            }
            return e && handleBlur(e);
          };

          const renderInlineAdd = () => {
            if (inlineAddModal) {
              const Component = Route.matchRoute({ pathname: (inlineAddModal.routePath)() }).DetailComponent;
              return (
                <>
                  <Header
                    label={`New ${inlineAddModal.label}`}
                  />
                  <Component
                    apiPath={inlineAddModal.apiPath}
                    schema={inlineAddModal.schema}
                    dataParent={values}
                    modelParent={model}
                    nested
                    onSubmit={async (values) => {
                      const { data: item } = await axios.post(inlineAddModal.apiPath, values);
                      inlineAddModal.field.form.items.unshift({ value: item[inlineAddModal.field.form.valueKey], label: item[inlineAddModal.field.form.labelKey] });
                      setFieldValue(inlineAddModal.field.key, item.id);
                      if (isEdit) {
                        submitValue(inlineAddModal.field, item.id);
                      }
                      hideInlineAddModal();
                    }}
                  />
                </>
              );
            }
            return null;
          };

          const renderField = (field) => {
            const { key, type, label, required, decimals, email, linkedIn, form = {} } = field;

            let value = form.valueGetter ? form.valueGetter(values) : get(values, key);
            if (field.many) {
              value = value ? value.map((item) => typeof item === 'object' ? item[form.valueKey] : item) : [];
            } /* else if (decimals && value != null) {
              value = parseFloat(value).toFixed(2);
            } */
            form.setFieldValue = setFieldValue;
            form.submitValue = submitValue;
            const detail = (field.form?.detail ?? field.grid?.detail)?.();

            /* if (form.readOnly) {
              return (
                <div className="readonly">
                  <div className="label">{label}</div>
                  <div className="value">{value}</div>
                </div>
              );
            } */

            if (form.info) {
              return (
                <>
                  <div className="label">{label}</div>
                  <div
                    className={classNames('value', { detail })}
                    {...detail && {
                      onClick: async () => {
                        const primaryKey = get(data, detail.key);
                        if (primaryKey) {
                          setNestedDetail({
                            ...detail,
                            data: (await axios.get(`${detail.apiPath}/${primaryKey}`)).data,
                          });
                        }
                      },
                    }}
                  >
                    {
                      form.infoRenderer ? form.infoRenderer(data) :
                      type === 'boolean' ? (value ? 'Yes' : 'No') :
                      isEmpty(value) ? '-' :
                      type === 'number' ? value?.toLocaleString() :
                      Array.isArray(value) ? value.join(', ') :
                      type === 'date' ? (field.time && !moment(value).utc().isSame(moment(value).utc().startOf('date')) ? moment(value).format('M/D/YYYY h:mm a') : moment(value).format('M/D/YYYY')) :
                      <>
                        {detail && <i className={`fa fa-${iconType.LINK.className}`} />}
                        {value}
                      </>
                    }
                  </div>
                </>
              );
            }

            if (form.map) {
              return <Map
                latitude={values.latitude}
                longitude={values.longitude}
                googlePlaceId={data?.googlePlaceId}
              />;
            }

            const empty = value === null || value === undefined || value === '';
            const active = !empty;

            let fieldElem = null;
            if (form.type === 'textarea') {
              const onChangeDebounce = useCallback(
                debounce((e, data, dataParent) => {
                  form.onChange({ data, dataParent, value: e.target.value, values, key: form.nested ? key.split('.').pop() : key });
                }, 300),
                [],
              );

              const textarea = (icons) =>
                <MDBTextArea
                  className={classNames({ active: !!value, invalid: isInvalid(field) })}
                  size="sm"
                  type={type}
                  label={label}
                  id={key}
                  name={key}
                  onChange={(e) => {
                    handleChange(e);
                    if (form.onChange) {
                      onChangeDebounce(e, data, dataParent); // eslint-disable-line
                    }
                  }}
                  onBlur={(e) => onBlur(field, e)}
                  value={value || ''}
                  required={required}
                  //style={{ minHeight: form.minHeight }}
                >
                  {icons}
                </MDBTextArea>;
              fieldElem =
              <>
                {
                  textarea(
                    <div className="icons">
                      {[
                        {
                          type: iconType.FULL_SCREEN,
                          onClick: () => setTextareaFullScreen(form),
                          tooltip: 'Full Screen',
                        },
                        ...form.icons ?? [],
                      ].map(({ type, className, onClick, tooltip }, i) => {
                        const icon =
                          <i
                            className={classNames(`fa fa-${className || type.className} icon`, type.key)}
                            onClick={() => onClick({
                              ...props,
                              setFieldValue: (key, value) => {
                                setFieldValue(key, value);
                                submitValue(getField(key), value);
                              },
                            })}
                            key={i}
                          />;
                        return tooltip ?
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{tooltip}</Tooltip>}
                            key={i}
                          >
                            {icon}
                          </OverlayTrigger> :
                          icon;
                      })}
                    </div>,
                  )
                }
                <Modal
                  showing={textareaFullScreen === form}
                  onHide={() => setTextareaFullScreen(null)}
                  containerWidth
                  body={textarea()}
                />
              </>;
            } else if (form.type === 'checkbox') {
              if (field.many) {
                fieldElem =
                  form.items?.length ?
                  <div>
                    <MDBInput
                      className={classNames({ active: true, invalid: isInvalid(field) })}
                      size="sm"
                      label={label}
                      id={key}
                      name={key}
                    >
                      {/* {!form.items?.length && required && <Alert variant="secondary" className="form empty">{`No ${pluralize(label).toLowerCase()}`}</Alert>} */}
                      <Row>
                        {(form.itemsWrap ? chunk(form.items, form.itemsWrap) : [form.items]).map((items, i) =>
                          <Col key={i} className="col" md="auto">
                            {items.map((item, i) => {
                              const checked = value?.includes(item.value);
                              return (
                                <div
                                  key={i}
                                  onClick={() => {
                                    if (!checked) {
                                      value.push(item.value);
                                    } else {
                                      value.splice(value.indexOf(item.value), 1);
                                    }
                                    setFieldValue(key, value);
                                    submitValue(field, value);
                                  }}
                                >
                                  <FormCheck
                                    type="checkbox"
                                    value={item.value}
                                    label={item.label}
                                    checked={checked}
                                    //onChange={() => {}}
                                    className={classNames({ checked })}
                                  />
                                </div>
                              );
                            })}
                          </Col>)}
                      </Row>
                    </MDBInput>
                  </div> : null;
               } else {
                fieldElem =
                  <Button
                    variant="outline-secondary"
                    className={classNames({ active: !!value })}
                    onClick={() => {
                      if (form.onClick) {
                        form.onClick();
                      } else {
                        setFieldValue(key, !value);
                        submitValue(field, !value);
                      }
                    }}
                  >
                    {label}
                    {form.iconType && <i className={`fa fa-${form.iconType.className}`} />}
                  </Button>;
              }
            } else if (form.type === 'autocomplete') {
              // TODO: investigate large dataset - https://github.com/downshift-js/downshift/issues/549
              const inputRef = useRef();
              fieldElem =
                <Downshift
                  onChange={(item) => {
                    if (isAdd) {
                      setFieldValue(key, item?.value);
                      form.onChange?.({ item, values, fields, setFieldValue });
                    } else {
                      form.onChange?.({
                        item,
                        values,
                        fields,
                        setFieldValue: (key, value, { submit = true } = {}) => {
                          setFieldValue(key, value);
                          if (submit) {
                            submitValue(getField(key), value);
                          }
                        },
                        setValues: (_values) =>
                          setValues({ ...values, ..._values, ...{ [key]: item.value } }),
                      });
                      if (!required || item?.value) {
                        setFieldValue(key, item?.value);
                        submitValue(field, item?.value);
                      }
                    }
                  }}
                  itemToString={(item) => (form.freeForm ? value : item?.label) || ''}
                  selectedItem={form.items?.find((item) => item.value === value)}
                  initialInputValue={form.freeForm && (form.items?.find((item) => item.value === value)?.label || value || '')}
                  onSelect={() => {
                    inputRef.current.blur();
                  }}
                >
                  {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    inputValue,
                    highlightedIndex,
                    getRootProps,
                    clearSelection,
                    openMenu,
                    selectedItem,
                    selectItem,
                  }) => {
                    const nofilter = form.nofilter || form.items?.length < 20;
                    const items =
                      isOpen ?
                        nofilter || !inputValue || inputValue === value ?
                          [ !required && form.items?.length > 0 && { value: null, label: '-' }, ...form.items ].filter(Boolean) :
                          form.items.filter((item) => item.label?.toLowerCase().includes(inputValue.toLowerCase())) :
                        [];
                    return (
                      <div {...getRootProps({}, { suppressRefError: true })}>
                        <MDBInput
                          {...getInputProps({
                            onFocus: openMenu,
                            onKeyDown: (e) => {
                              e.nativeEvent.preventDownshiftDefault = [ 'Home', 'End' ].includes(e.key);
                              if (e.key === 'Enter' && items.length === 1) {
                                selectItem(items[0]);
                              }
                            },
                          })}
                          className={classNames({ nofilter, empty: !value, invalid: isInvalid(field) })}
                          size="sm"
                          label={label}
                          id={key}
                          name={key}
                          required={required}
                          readOnly={form.readOnly}
                          onClick={(e) => {
                            if (nofilter) {
                              e.target.blur();
                            }
                          }}
                          onBlur={(e) => {
                            if (e.relatedTarget && !e.relatedTarget.classList.contains('list')) {
                              if (!inputValue) {
                                setFieldValue(key, inputValue);
                                if (!required) {
                                  submitValue(field, null);
                                }
                                clearSelection();
                              } else if (form.freeForm && selectedItem?.label !== inputValue) {
                                setFieldValue(key, inputValue);
                                submitValue(field, inputValue);
                              }
                            }
                          }}
                          inputRef={inputRef}
                        >
                          {form.inlineAdd ?
                          <i
                            onClick={() => setInlineAddModal({
                              ...form.inlineAdd,
                              field,
                            })}
                            className="fa fa-plus inline-add"
                          /> :
                          form.button ?
                          <Button
                            variant="outline-secondary"
                            onClick={form.onClick}
                          >
                            {label}
                          </Button> : null}
                          {detail && selectedItem &&
                          <i
                            onClick={async () => {
                              setNestedDetail({
                                ...detail,
                                data: (await axios.get(`${detail.apiPath}/${selectedItem.primaryKey ?? selectedItem.value}`)).data,
                              });
                            }}
                            className={`fa fa-${iconType.LINK.className}`}
                          />}
                        </MDBInput>
                        {isOpen && (!form.freeForm || items.length) ?
                          <AutoSizer disableHeight>
                            {({ width }) => (
                              <List
                                className="list"
                                width={width}
                                height={Math.min((Math.max(items.length, 1) + 1) * 20, 300)}
                                scrollToIndex={highlightedIndex || 0}
                                overscanRowCount={10}
                                rowCount={items.length}
                                rowHeight={20}
                                rowRenderer={({ index, key, style }) => {
                                  const item = items[index];
                                  return (
                                    <div
                                      {...getItemProps({
                                        key: item.value,
                                        index,
                                        item,
                                        // style: {
                                        //   backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                                        //   fontWeight: selectedItem === item ? 'bold' : 'normal',
                                        // },
                                      })}
                                      className={classNames('item', { active: highlightedIndex === index })}
                                      key={key}
                                      style={style}
                                    >
                                      <Highlighter
                                        searchWords={[inputValue]}
                                        autoEscape
                                        textToHighlight={item.label}
                                      />
                                    </div>
                                  );
                                }}
                                noRowsRenderer={() => (
                                  <div className="item empty">
                                    {`No ${pluralize(form.labelEmpty || label).toLowerCase()}`}
                                  </div>
                                )}
                              />
                            )}
                          </AutoSizer> : null }
                      </div>
                    );
                  }}
                </Downshift>;
            } else if (form.type === 'tag') {
              const tags = value && value.length ? (typeof value[0] === 'string' ? value.map((item) => ({ id: item, text: item })) : value) : [];

              const handleAddition = (tag) => {
                if (tag && tag.text) {
                  tags.push(tag);
                  setFieldValue(key, tags);
                  submitValue(field, tags);
                }
              };

              const selectedClass = 'selected';
              fieldElem =
                <div
                  ref={(el) => {
                    if (el) {
                      el.addEventListener('click', (e) => {
                        if (e.target.classList.contains(selectedClass)) {
                          e.target.parentNode.previousSibling.querySelector('input').focus();
                        }
                      });
                    }
                  }}
                >
                  <MDBInput
                    className={classNames({ active: tags.length, focused: fieldFocused === field, empty: !value })}
                    size="sm"
                    label={label}
                    id={key}
                    name={key}
                    onFocus={(e) => {
                      e.target.parentNode.nextSibling.querySelector('input').focus();
                    }}
                  />
                  <div
                    ref={(el) => {
                      if (el?.firstChild) {
                        el.firstChild.style.height = `${el.closest('.tag').firstChild.offsetHeight - 3}px`;
                      }
                    }}
                  >
                    <ReactTags
                      classNames={{
                        selected: selectedClass,
                      }}
                      tags={tags}
                      delimiters={[ ...KEYS.ENTER, KEYS.TAB ]}
                      autofocus={false}
                      allowDragDrop={false}
                      handleInputFocus={() => {
                        setFieldFocused(field);
                      }}
                      handleInputBlur={(blurValue) => {
                        setFieldFocused();
                        handleAddition({ id: blurValue, text: blurValue });
                      }}
                      handleAddition={handleAddition}
                      handleDelete={(index) => {
                        tags.splice(index, 1);
                        setFieldValue(key, tags);
                        submitValue(field, tags);
                      }}
                      inputFieldPosition="bottom"
                      placeholder=""
                      minQueryLength={0}
                      /* suggestions={[{ id: 'aaa', text: 'aaa' }, { id: 'bbbaaa', text: 'bbbaaa' }, { id: 'cccaaa', text: 'cccaaa' }]}
                      autocomplete */
                      /*editable
                      suggestions={suggestions}
                      delimiters={delimiters}
                      handleDelete={handleDelete}
                      handleAddition={handleAddition}
                      handleDrag={handleDrag}
                      handleTagClick={handleTagClick}
                      autocomplete*/
                    />
                  </div>
                </div>;
            } else if (field.type === 'image') {
              const inputRef = useRef();
              const renderUpload = (component) =>
                !form.disabledClick ?
                  <Upload
                    accept="image/*"
                    disabled={!!form.readOnly}
                    customRequest={({ file }) => {
                      setIsUploading(true);
                      const formData = new FormData();
                      formData.append(key, file);
                      (form.handleSubmit ? form.handleSubmit({ formData }) : axios.patch(`${apiPath}/${data[primaryKey]}`, formData)).then(({ data }) => {
                        setFieldValue(key, data[key]);
                        setIsUploading(false);
                      })
                        .catch((err) => {
                          console.log(err);
                          setIsUploading(false);
                        });
                    }}
                  >
                    {component}
                  </Upload> :
                  <div
                    onClick={form.disabledClick}
                  >
                    {component}
                  </div>;
              fieldElem =
                <>
                  <MDBInput
                    className="active"
                    size="sm"
                    label={label}
                    id={key}
                    name={key}
                    inputRef={inputRef}
                  />
                  <div>
                    {isUploading &&
                    <>
                      <Spinner />
                      <div />
                    </>}
                    {/* {form.download && <i
                      onClick={() => {
                        const ab = base64ToArrayBuffer(value);
                        saveAs(new Blob([ab], { type: 'image/jpeg' }), form.download.filename({ data, dataParent }));
                      }}
                      className={`fa fa-${iconType.EXPORT.className} download`}
                    />} */}
                    {
                      value && !form.readOnly && !isUploading &&
                      <>
                        {renderUpload(<i className="edit" />)}
                        <i
                          onClick={() => {
                            setFieldValue(key, null);
                            if (form.handleDelete) {
                              form.handleDelete();
                            } else {
                              submitValue(field, null);
                            }
                          }}
                          className="delete"
                        />
                      </>
                    }
                    {value ?
                      <img // eslint-disable-line
                        src={`data:image/jpg;base64,${value}`}
                        style={{ height: !form.heightAuto && inputRef.current?.offsetHeight }}
                        onClick={() => setImageModal({
                          form,
                          data: value,
                        })}
                      /> :
                      renderUpload(
                        <>
                          <FontAwesomeIcon icon={faImage} />
                          {!form.readOnly && !form.lazyLoading && !isUploading && <FontAwesomeIcon icon={faPlus} />}
                          {form.lazyLoading && <Spinner />}
                        </>,
                      )}
                  </div>
                </>;
            } else if (field.type === 'date') {
              if (form.hide) {
                return null;
              }
              fieldElem =
                <>
                  <MDBInput
                    className={classNames({ active, focused: fieldFocused === field, empty: !value, invalid: isInvalid(field) })}
                    size="sm"
                    label={label}
                    id={key}
                    name={key}
                    readOnly={form.readOnly}
                  />
                  <DatePicker
                    dateFormat="M/d/yyyy"
                    selected={value ? moment(value).toDate() : null}
                    showPopperArrow={false}
                    showYearDropdown
                    maxDate={moment().add(2, 'years').toDate()}
                    onChange={(date) => {
                      value = date ? moment(date).format('yyyy-MM-DD') : null;
                      setFieldValue(key, value);
                      if (value) {
                        setFieldFocused();
                      }
                      if (isEdit) {
                        submitValue(field, value);
                      }
                      form.onChange?.({ value, fields, setFieldValue, values });
                    }}
                    onFocus={() => {
                      setFieldFocused(field);
                    }}
                    onBlur={() => {
                      setFieldFocused();
                    }}
                    disabled={form.readOnly}
                  />
                </>;
            } else {
              const inlineButton = form.inlineButton &&
                <Button
                  variant="outline-secondary"
                  onClick={() => form.inlineButton.onClick(field)}
                  className="inline-button"
                >
                  {form.inlineButton.label}
                </Button>;

              fieldElem =
                <div onClick={form.onClick}>
                  <MDBInput
                    className={classNames({ active, invalid: isInvalid(field), empty })}
                    size="sm"
                    type={type}
                    label={label}
                    id={key}
                    /* onChange={handleChange} */
                    onChange={(e) => {
                      handleChange(e);
                      form.onChange?.({ value: e.target.value, fields, setFieldValue, values });
                    }}
                    /* form.onChange?.({ value, fields, setFieldValue, values }); */
                    onBlur={(e) => onBlur(field, e)}
                    value={active ? value : ''}
                    required={required}
                    readOnly={form.readOnly}
                    {...(type === 'email' || type === 'password') && {
                      autoComplete: 'new-password',
                      'data-lpignore': 'true',
                    }}
                  >
                    {(type === 'email' || linkedIn) && <i
                      onClick={() => {
                        window.open(`${type === 'email' ? 'mailto:' : ''}${value}`, '_blank');
                      }}
                      className={`fa fa-${iconType.LINK.className}`}
                    />}
                    {form.icons &&
                      <div className="icons">
                        {form.icons.map(({ type, className, onClick, tooltip, disabled }, i) => {
                          const icon =
                            <i
                              className={classNames(`fa fa-${className || type.className} icon`, type.key, { disabled })}
                              onClick={() => onClick({
                                ...props,
                                setFieldValue: (key, value) => {
                                  setFieldValue(key, value);
                                  submitValue(getField(key), value);
                                },
                              })}
                              key={i}
                            />;
                          return tooltip ?
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip>{tooltip}</Tooltip>}
                              key={i}
                            >
                              {icon}
                            </OverlayTrigger> :
                            icon;
                        })}
                      </div>}
                    {form.iconType && <i className={`fa fa-${form.iconType.className} linked`} />}
                    {inlineButton && (form.inlineButton.tooltip ?
                      <OverlayTrigger
                        placement="top"
                        trigger={form.inlineButton.tooltipTrigger || 'hover'}
                        overlay={<Tooltip>{form.inlineButton.tooltip}</Tooltip>}
                      >
                        {inlineButton}
                      </OverlayTrigger> :
                      inlineButton
                    )}
                  </MDBInput>
                </div>;
            }

            return fieldElem && (
              <MDBValidationItem
                feedback={errors[field.key]}
                invalid
                className={classNames(field.form?.type || field.type, form.className, { many: field.many, 'has-height': form.height, 'has-icons': form.icons, nolabel: !field.label, readonly: form.readOnly, bold: field.bold, logo: form.logo })}
                style={{ height: form.height }}
              >
                {fieldElem}
              </MDBValidationItem>
            );
          };

          return (
            <>
              <MDBValidation noValidate onSubmit={handleSubmit}>
                <Container>
                  {rowFields.map((colFields, row) =>
                    <Row key={row}>
                      {colFields.filter((col) => col.length && (!col?.[0]?.form?.map || (values.latitude && values.longitude))).map((col, i) =>
                        <Col className={classNames('col', { 'full-height': col.length === 1 && colFields.length > 1 })} md={col?.[0]?.form?.colWidth} key={i}>
                          {col.map((field, i) => (
                            <React.Fragment key={i}>
                              {Array.isArray(field) ? (
                                field.some(({ form }) => !form?.hide) ?
                                  <Row>
                                    {field.map((field, i) => <Col className="col" md={field.form?.colWidth} key={i}>{renderField(field)}</Col>)}
                                  </Row> : null
                              ) : renderField(field)}
                            </React.Fragment>
                          ))}
                        </Col>)}
                      {data && infoFields.length && row === 0 ?
                        <>
                          <Col md="auto" className="info">
                            {infoFields
                              .filter((field, i) => !infoFieldColBreakIndex || i < infoFieldColBreakIndex)
                              .map((field, i) => <React.Fragment key={i}>{renderField(field)}</React.Fragment>)}
                          </Col>
                          {infoFieldColBreakIndex &&
                          <Col md="auto" className="info">
                            {infoFields
                              .filter((field, i) => i >= infoFieldColBreakIndex)
                              .map((field, i) => <React.Fragment key={i}>{renderField(field)}</React.Fragment>)}
                          </Col>}
                        </> : null}
                    </Row>)}
                  {!isEdit && !hideSubmit &&
                    <Row className="submit">
                      <div>
                        <Button
                          variant="secondary"
                          type="submit"
                          /* disabled={true} */
                          disabled={isSubmitting}
                          onClick={() => setHasSubmitted(true)}
                        >
                          {submitLabel || (isAdd ? 'Add' : 'Update')}
                          {isSubmitting && <Spinner />}
                        </Button>
                        {submitError ? <Alert variant="danger">{submitError}</Alert> : null}
                      </div>
                    </Row>}
                </Container>
              </MDBValidation>
              <Modal
                showing={!!inlineAddModal}
                onHide={hideInlineAddModal}
                containerWidth
                body={renderInlineAdd()}
              />
              <Modal
                showing={!!submitWarnMsg}
                onHide={() => setSubmitWarnMsg(null)}
                confirm
                onSubmit={() => { onSubmit({ warnConfirm: submitWarnMsg }); setSubmitWarnMsg(null); }}
                body={
                  <>
                    <div>{submitWarnMsg}</div>
                    <div>Are you sure you want to continue?</div>
                  </>
                }
              />
              <Modal
                showing={!!imageModal}
                onHide={() => setImageModal(null)}
                containerWidth
                className="image"
                body={
                  <img // eslint-disable-line
                    src={`data:image/jpg;base64,${imageModal?.data}`}
                    onClick={() => {
                      saveAs(
                        new Blob([base64ToArrayBuffer(imageModal?.data)], { type: 'image/jpeg' }),
                        imageModal?.form.download.filename({ data, dataParent }),
                      );
                    }}
                  />
                }
              />
              {nestedDetail &&
              <Modal
                showing={!!nestedDetail}
                onHide={() => setNestedDetail(null)}
                containerWidth
                body={
                  <Crud
                    apiPath={nestedDetail.apiPath}
                    schema={nestedDetail.schema}
                    model={nestedDetail.model}
                    label={nestedDetail.label}
                    routePath={nestedDetail.routePath}
                    nestedDetail={nestedDetail}
                  />
                }
              />}
            </>
          );
        }}
      </Formik>
    </div> : null;
};
