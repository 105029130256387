import React, { useState, useReducer } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { pick, cloneDeep, capitalize } from 'lodash';
import axios from 'axios';
import pluralize from 'pluralize';
import Form from 'components/form';
import Tabs from 'components/tabs';
import Crud from 'components/crud';
import { alterSchema } from 'utils/schema';
import * as Contact from 'views/contact/config';
import * as Campaign from 'views/campaign/config';
import * as Activity from 'views/activity/config';
import Attachment from 'views/attachment/index';
import { model } from './config';
import 'styles/detail.scss';

const ClientDetail = ({ id, apiPath, schema, data, dataParent, modelParent, onSubmit, nested }) => {
  if (!nested) {
    [ id, apiPath, schema, data, onSubmit ] = useOutletContext();
  }

  /* const [ nestedGridHeight, setNestedGridHeight ] = useState(null);
  const [ nestedFormHeight, setNestedFormHeight ] = useState(null); */

  const [ , forceUpdate ] = useReducer((x) => x + 1, 0, () => {});

  const schemaClientContact = (type) => {
    const typeKey = (key) => type === 'billing' ? `${type}${capitalize(key)}` : key;
    const fields = cloneDeep(pick(schema, [
      ...[ `id_${type}Contact`, 'id' ],
      ...[ 'email', 'phone', 'mobile', 'fax', 'address', 'city', 'state', 'zip' ].map((key) => typeKey(key)),
    ]));

    return Object.keys(fields).reduce((acc, key, i) => ({
      ...acc,
      [key]: {
        ...fields[key],
        form: {
          ...fields[key].form,
          hide: !!fields[key].primaryKey,
          ...i === 0 && {
            onChange: async ({ item, setFieldValue }) => {
              if (item) {
                const { data } = await axios.get(`${Contact.apiPath}/${item.value}`);
                [ 'email', 'phone', 'mobile', 'fax' ].forEach((key) => setFieldValue(typeKey(key), data[key]));
              }
            },
          },
        },
      },
    }), {});
  };

  return (
    <div className="detail client">
      <Form
        apiPath={apiPath}
        schema={schema}
        data={data}
        onSubmit={onSubmit}
      />
      {data && <Tabs
        tabs={[
          {
            key: Contact.model,
            title: pluralize(Contact.label),
            content:
              <div
                ref={(el) => {
                  /* if (el) {
                    //setNestedGridHeight(200);
                    //setNestedGridHeight(window.innerHeight - el.offsetTop - nestedFormHeight - 100);
                  } */
                }}
              >
                <Crud
                  apiPath={Contact.apiPath}
                  apiParams={{ clientId: id }}
                  schema={alterSchema(Contact.schema, { gridCols: [ 'id', 'nameFull', 'title', 'email', 'phone' ] })}
                  schemaParent={schema}
                  routePath={Contact.routePath}
                  label={Contact.label}
                  nested
                  dataParent={data}
                  model={Contact.model}
                  modelParent={model}
                  gridHeight={200}
                  onInit={() => {
                    forceUpdate();
                  }}
                />
                <Row
                  ref={(el) => {
                    /* if (el) {
                      setNestedFormHeight(el.getBoundingClientRect().height);
                    } */
                  }}
                >
                  <Col>
                    <Form
                      apiPath={apiPath}
                      schema={schemaClientContact('primary')}
                      data={data}
                    />
                  </Col>
                  <Col>
                    <Form
                      apiPath={apiPath}
                      schema={schemaClientContact('billing')}
                      data={data}
                    />
                  </Col>
                </Row>
              </div>,
          },
          {
            key: 'campaign',
            title: 'Campaigns',
            content:
              <Crud
                apiPath={Campaign.apiPath}
                apiParams={{ clientId: id }}
                schema={alterSchema(Campaign.schema, { gridCols: [ 'title', 'type', 'markets', 'dateStart', 'dateEnd', 'status' ] })}
                schemaParent={schema}
                routePath={Campaign.routePath}
                label={Campaign.label}
                nested
                dataParent={data}
                model={Campaign.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'activity',
            title: 'Activities',
            content:
              <Crud
                apiPath={Activity.apiPath}
                apiParams={{ clientId: id }}
                schema={alterSchema(Activity.schema, { gridColsOrder: Activity.nestedGridCols })}
                schemaParent={schema}
                routePath={Activity.routePath}
                label={Activity.label}
                nested
                dataParent={data}
                model={Activity.model}
                modelParent={model}
                onInit={() => {
                  forceUpdate();
                }}
              />,
          },
          {
            key: 'attachment',
            title: 'Attachments',
            content:
              <Attachment
                schemaParent={schema}
                modelParent={model}
                dataParent={data}
              />,
          },
        ]}
      />}
    </div>
  );
};

export default ClientDetail;
